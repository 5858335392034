import Handlebars = require("handlebars");
import { Controller } from "../../classes/mvc/Controller";
import { modules } from "../../main";
import { IDeliveryAddresses } from "./Interfaces/IDeliveryAddresses";
import { ModelDeliveryAddresses } from "./ModelDeliveryAddresses";

declare let ezentrum_session_vars: any;
export class ControllerDeliveryAddresses extends Controller<ModelDeliveryAddresses> {

    private static deliverAddresses: Array<IDeliveryAddresses>;

    private outputButtonCountainer: JQuery<HTMLElement>;
    private outputDesktopContainer: JQuery<HTMLElement>;
    private static desktopOpenText: JQuery<HTMLElement>;

    private buttonTemplate: string;
    private desktopTemplate: string;
    private static sKontaktID: string;
    private static sKontaktKEY: string;

    public constructor( accessName: string, accessID: number, outputContainer:JQuery<HTMLElement>, outputDesktopContainer:JQuery<HTMLElement> ) {
        super( new ModelDeliveryAddresses(), accessName, accessID );

        this.outputButtonCountainer = outputContainer;
        this.outputDesktopContainer = outputDesktopContainer;
    }

    public initGlobals(): void {
        this.buttonTemplate = this.getModule().getConfig("button_template");
        this.desktopTemplate = this.getModule().getConfig("desktop_template");

        ControllerDeliveryAddresses.sKontaktID = modules.getKontaktID();
        ControllerDeliveryAddresses.sKontaktKEY = modules.getKontaktKey();

        if ( 'deliver_adresses' in ezentrum_session_vars ) {
            ControllerDeliveryAddresses.deliverAddresses = ezentrum_session_vars.deliver_adresses;
        } else {
            modules.error("'deliver_adresses' existiert nicht in den 'ezentrum_session_vars'!");
        }
    }

    public run(): void {
        if ( ControllerDeliveryAddresses.deliverAddresses.length > 0 ) {
            this.renderButtonHTML();
            this.renderDesktopHTML();

            ControllerDeliveryAddresses.desktopOpenText = jQuery('#deliveryaddress_open_desktop');

            this.assignDesktopEvents();
            this.setSelected();
        }
    }

    private renderButtonHTML(): void {
        if ( this.buttonTemplate !== "" ) {
            let handlebarTemplate: HandlebarsTemplateDelegate<any> = Handlebars.compile( this.buttonTemplate );
            let result: string = handlebarTemplate("");
            this.outputButtonCountainer.html(result);
        }
    }

    private renderDesktopHTML(): void {
        let handlebarTemplate: HandlebarsTemplateDelegate<any> = Handlebars.compile( this.desktopTemplate );
        let result: string = handlebarTemplate( ControllerDeliveryAddresses.deliverAddresses );
        this.outputDesktopContainer.html(result);
    }

    private assignDesktopEvents(): void {
        let lieferanschriftenLabels = jQuery('.lieferanschrift-label');

        lieferanschriftenLabels.off('click').on('click', function() {
            let selectedAddressIndex: string = jQuery(this).attr('data-ez-address-index');

            let htmlIndex = Number(selectedAddressIndex)+1;
            ControllerDeliveryAddresses.reRenderText( ControllerDeliveryAddresses.desktopOpenText, "Anschrift " + htmlIndex );

            ControllerDeliveryAddresses.setLieferanschrift( ControllerDeliveryAddresses.deliverAddresses[selectedAddressIndex].id ) ;
        });

        let lieferanschriftAddEl: JQuery<HTMLElement> = jQuery('#ez_lieferanschrift_add');
        lieferanschriftAddEl.on('click', function() {
            jQuery('.lieferanschrift-add-container').slideToggle();
        });

        let lieferanschriftAddButton: JQuery<HTMLElement> = jQuery('[data-ez-add-address-button]');
        lieferanschriftAddButton.on('click', function() {
            // TODO: Funktion einfügen zur erstellung einer neuen Lieferaddresse
        });
    }

    private static reRenderText( element: JQuery<HTMLElement>, text: string ): void {
        element.html(text);
    }

    private setSelected(): void {
        let selectedAddressID: number;
        let selectedAnschriftID: number;

        for(var i = 0; i < ControllerDeliveryAddresses.deliverAddresses.length; i++) {
            if ( ControllerDeliveryAddresses.deliverAddresses[i].selected ) {
                selectedAddressID = ControllerDeliveryAddresses.deliverAddresses[i].id;
                selectedAnschriftID = i+1;
                ControllerDeliveryAddresses.reRenderText( ControllerDeliveryAddresses.desktopOpenText, "Anschrift " + selectedAnschriftID );
            }
        }

        jQuery('input[data-ez-lieferanschrift-id="'+ selectedAddressID +'"]').prop('checked', true);
    }

    private static setLieferanschrift( id: number ): void {
        // Aufbau der URL für den AJAX-Call
        let url: string = "/4DCGI/ezshop?action=JSON_setLieferAnsch&pid=" + id + "&sKontaktID=" + ControllerDeliveryAddresses.sKontaktID + "&sKontaktKEY=" + ControllerDeliveryAddresses.sKontaktKEY;

        // Call an den Server, um das Lieferland zu wechseln.
        jQuery.ajax({
            url: url,
            type: "GET",
            success: function( data  ) {
                console.log(data);
            },
            error: function(error) {
                modules.error("Fehler beim ändern der Lieferanschrift!", true);
            }
        });
    }
}