import { modules } from "../../main";
import { Model } from "./../../classes/mvc/Model";
import { AjaxRequest } from "./../../libs/AjaxRequest";

export class ModelAutocomplete extends Model{

    public constructor (){
        super();
    }

    public getSearchResults ( url:string ):Object{
		return AjaxRequest.getJson( url );
	}

    public getSearchResultByCategory( searchID: string, type: string, thumbName: string, query_word: string = "" ):Object {
        let result: Object = null;
        
        let url = "/caspardosearch/servlet/findIt?searchengineid="+ searchID +"&output=json&version=2.0&searchtype=6&tab=shop&type=shop&language="+ modules.getLanguageCode() +"&orderby=1&pagetype=1&page=1&cat_op=1&orderby_rank=2";

        switch (type) {
            case "category":
                url += "&category1=" + thumbName + "&query=" + query_word;
                break;
            case "brand":
                url += "&brand=" + thumbName + "&query=" + query_word;
                break;
            case "keyword":
                url += "&query=" + thumbName
                break;
        }

        jQuery.ajax({
			url: url,
			type: "GET",
			async: false,
			dataType: "json",
			success: function ( response:Object ){

				result = response;

			}.bind(this)
		});


        return result;
    }

    public getSearchResultByBrand( searchID: string, brand: string ):Object {
        let result: Object = null;

        let url = "/caspardosearch/servlet/findIt?searchengineid="+ searchID +"&output=json&version=2.0&searchtype=6&tab=shop&type=shop&language="+ modules.getLanguageCode() +"&orderby=1&pagetype=1&page=1&brand="+ brand +"&query=&cat_op=1&orderby_rank=2";

        jQuery.ajax({
			url: url,
			type: "GET",
			async: false,
			dataType: "json",
			success: function ( response:Object ){

				result = response;

			}.bind(this)
		});


        return result;
    }
}