import { ModelPagination } from "./ModelPagination";
import { Controller } from "../../classes/mvc/Controller";
import { modules } from "../../main";

import jQuery = require("jquery");

import "jquery-ui";

declare let currentPageSite: any;
export class ControllerPagination extends Controller<ModelPagination> {

    private listing_form:JQuery<HTMLFormElement>;
    private sort_select: any;

    private page_select:any;
    private page_selector:JQuery<HTMLElement>;
    private page_select_items:JQuery;
    private page_select_form_input:JQuery<HTMLElement>;

    private isArticleFilters: boolean = true;
    private isFormListing: boolean = false;

    public constructor(accessName: string, accessID: number) {
        super(new ModelPagination(), accessName, accessID);
    }

    public initGlobals():void {
        var article_form = document.querySelectorAll("#article_filters");

        if ( article_form.length > 0 ) {
            this.listing_form = jQuery("#article_filters");
            this.isArticleFilters = true;
            this.isFormListing = false;
        } else {
            this.listing_form = jQuery("#form_listing");
            this.isFormListing = true;
            this.isArticleFilters = false;
        }

        this.sort_select = document.querySelectorAll(".ez_sort_select");

        this.page_select = jQuery(".ez_pageselect");
        this.page_selector = this.page_select.find(".page-selector");
        this.page_select_items = this.page_select.find(".pagination_item");
        this.page_select_form_input = this.listing_form.find("input[name='Suchstartpos']");
    }

    public run():void {
        this.checkIfElementsExist();

        // this.sort_select.on("change", function(e) {
        //     this.listing_form.submit();
        // }.bind(this));
        for ( var i = 0; i < this.sort_select.length; i++ ) {
            this.sort_select[i].addEventListener("change", function(e) {
                let suchstartpos = e.target.value;
                if ( this.isArticleFilters ) {
                    this.setFormVar('article_filters','Suchstartpos', suchstartpos);
                }
                if ( this.isFormListing ) {
                    this.setFormVar('form_listing','Suchstartpos', suchstartpos);
                }
                this.listing_form.submit();
            }.bind(this));
        }

        this.page_selector.on("change", function(e) {
            let suchstartpos = e.target.value;
            if ( this.isArticleFilters ) {
                this.setFormVar('article_filters','Suchstartpos', suchstartpos);
            }
            if ( this.isFormListing ) {
                this.setFormVar('form_listing','Suchstartpos', suchstartpos);
            }
            this.listing_form.submit();
        }.bind(this));

        this.page_select_items.on("click", function(e) {
            let dataVal = e.target.dataset.value;

            if (dataVal == undefined || typeof dataVal == "undefined") {
                dataVal = e.target.parentNode.dataset.value;
            }

            this.page_select_form_input.val( dataVal );
            this.listing_form.submit();
        }.bind(this));
    }

    public setFormVar(formid:string, varname:string, value:string) {
        if ( formid !== "" && varname !== "" && value !== "" ) {
            let txt:string = "document.getElementById('"+ formid +"')."+ varname +".value='"+ value +"'";

            eval(txt);
        } else {
            this.getModule().error( "setFormVar Fehler: Eins der Parameter ist leer!" );
        }
    }

    public checkIfElementsExist():void {
        if (this.listing_form.length == 0) {
            this.getModule().error( "Das Element mit der ID( article_filters ) wurde nicht gefunden!" );
        }
        if (this.sort_select.length == 0) {
            this.getModule().error( "Das Element mit der ID( sort_select ) wurde nicht gefunden!" );
        }
        if (this.page_select.length == 0) {
            this.getModule().error( "Das Element mit der Klasse( ez_pageselect ) wurde nicht gefunden!" );
        }
        if (this.page_selector.length == 0) {
            this.getModule().error( "Das Select-Element mit der Klasse( page_selector ) wurde nicht gefunden!" );
        }
        if (this.page_select_items.length == 0) {
            this.getModule().error( "Die Elemente mit der Klasse( pagination_item ) wurden nicht gefunden!" );
        }
        if (this.page_select_form_input.length == 0) {
            this.getModule().error( "Das Input-Element mit dem Name( Suchstartpos ) wurde nicht gefunden!" );
        }
    }
}