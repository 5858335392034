import { ModelBasket } from "./ModelBasket";
import { Controller } from "../../classes/mvc/Controller";
import { modules } from "../../main";

import jQuery = require("jquery");


import "jquery-ui";
import { Notifications } from "../../classes/Notifications";
import { IResponseAddToKorb } from "./Interfaces/IResponseAddToKorb";

declare let currentPageSite: string;
declare let sWaehrnam: string;
export class ControllerBasket extends Controller<ModelBasket>{

    private setup_dialog_show: boolean = false;
    private setup_dialog_closetime: number = 3000;
    private add_class_to_cart_button: string;
    private mode: string;
    private do_refresh_price: boolean;
    private do_refresh_cart_html: boolean;

    private cart_button_text_in_cart: string;
    private cart_button_text_add_cart: string;
    private Label_addToCart: string;
    private Label_changeAmount: string;

    private highavail_amount:number = 0;
    public static tablerow_price:string = "";

    private static Notify: Notifications;

    public constructor(accessName: string, accessID: number) {
        super(new ModelBasket(), accessName, accessID);
    }

    public initGlobals(): void {
        let dialogShow = this.getModule().getConfig( "dialog_show" );
        let dialogCloseTime = this.getModule().getConfig( "dialog_closetime" );
        let addClass = this.getModule().getConfig( "add_class_to_cart_button" );
        let mode = this.getModule().getConfig( "mode" );
        let refresh_price = this.getModule().getConfig( "refresh_price" );
        let refresh_cart_html = this.getModule().getConfig( "refresh_cart_html" );
        this.highavail_amount = this.getModule().getConfig( "high_stockamount" );
        ControllerBasket.tablerow_price = this.getModule().getConfig( "tablerow_price" );
        let inCartText = this.getModule().getLabel( "cart_button_text_in_cart" );
        let addCartText = this.getModule().getLabel( "cart_button_text_add_cart" );

        let Label_addToCart = this.getModule().getLabel( "add_to_cart" );
        let Label_changeAmount = this.getModule().getLabel( "change_amount" );

        if ( dialogShow ) { this.setup_dialog_show = dialogShow }
        if ( dialogCloseTime ) { this.setup_dialog_closetime = dialogCloseTime }
        if ( addClass ) { this.add_class_to_cart_button = addClass }
        if ( mode ) { this.mode = mode }
        if ( refresh_price ) { this.do_refresh_price = refresh_price }
        if ( refresh_cart_html ) { this.do_refresh_cart_html = refresh_cart_html }

        if ( inCartText ) { this.cart_button_text_in_cart = inCartText }
        if ( addCartText ) { this.cart_button_text_add_cart = addCartText }
        if ( Label_addToCart ) { this.Label_addToCart = Label_addToCart }
        if ( Label_changeAmount ) { this.Label_changeAmount = Label_changeAmount }
    }

    public run(): void {
        this.assignEvents( this.setup_dialog_show, this.setup_dialog_closetime, this.cart_button_text_in_cart, this.cart_button_text_add_cart, this.add_class_to_cart_button, this.mode, this.do_refresh_price, this.do_refresh_cart_html, this.Label_addToCart, this.Label_changeAmount );
    }

    private assignEvents( setup_dialog_show, setup_dialog_closetime, cart_button_text_in_cart, cart_button_text_add_cart, class_to_add, mode, refresh_price, refresh_cart_html, Label_addToCart, Label_changeAmount ): void {

        jQuery(document.body).on('click', '.to_cart_button', function () {
            let art_id = this.value;  // Article-ID
            let art_nr = jQuery( this ).attr( 'data-artid' );
            let element_quantity;
            let element_field_container;
            let element_is_in_warenkorb_status;
            let parent_from_field_container;
            let cart_button;
            
            switch ( mode ) {
                case "datalist":
                    element_quantity = jQuery('[data-ez-datalist-artid="' + art_id + '"]');
                    cart_button = this;
                    ControllerBasket.Notify = new Notifications();
                    break;
                case "articlelist_vv": // neuer Weg für Vitaminversand (Artikel von Artikelliste in den Warenkorb legen)
                    element_quantity = jQuery('input[data-ez-artid="' + art_id + '"]');
                    element_field_container = jQuery('div[data-ez-field-container="' + art_id + '"]');
                    element_is_in_warenkorb_status = jQuery(element_field_container).attr('data-ez-is-in-warenkorb');
                    parent_from_field_container = jQuery(element_field_container).parent('div[data-ez-module-numberinput]');
                    ControllerBasket.Notify = new Notifications();
                    break;
                case "articlelist_neb": // neuer Weg für die NEB Artikelliste & Warenkorb
                    art_id = jQuery( this ).attr( 'data-articel-id' );
                    element_quantity = jQuery( "input[data-ez-update-basket="+ art_id +"]" )
                    break;
                default:
                    element_quantity = jQuery('.input-group-field');
                    break;
            }

            let quantity: string = (element_quantity.val());

            let url: string = '/4DCGI/ezshop?action=JSON_addToKorb' + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + "&sTICKCOUNT=" + modules.getTickcount() + `&artid=jQuery{art_id}&menge=jQuery{quantity}`;
            let result: any = {};
            /**
		    *
		    * Execute the ajax request
		    */
            jQuery.ajax({
                url: url,
                type: "GET",
                async: false,
                dataType: "json",
                success: function (response: IResponseAddToKorb) {
                    if ( response ) {

                        if ( refresh_price ) {
                            ControllerBasket.processRefreshPrice( art_id, response );
                        }

                        switch (mode) {
                            case "articlelist_neb":
                                ControllerBasket.processArticleListNEB( response, art_nr, quantity, Label_addToCart, Label_changeAmount );
                                break;
                            case "articlelist_vv":
                                ControllerBasket.processArticleListVV( quantity, element_field_container, element_is_in_warenkorb_status, parent_from_field_container, this, cart_button_text_in_cart, cart_button_text_add_cart, class_to_add );
                                break;
                            case "datalist":
                                ControllerBasket.processArticleDatalist( response, cart_button, Label_addToCart, Label_changeAmount );
                                break;
                        }
                    }
                }.bind(this)
            });

            if ( refresh_cart_html ) {
                url = `/filesync/warenkorb_preview`;

                jQuery.ajax({
                    url: url,
                    type: "GET",
                    async: false,
                    dataType: "html",
                    success: function (response: Object) {
                        result = response;
                    }.bind(this)
                });

                jQuery("#refresh").html(result);
            }

            if ( setup_dialog_show ) {

                if (quantity === "0") {
                    jQuery(".dialog_warenkorb_msg").html("Der Artikel entfernt.");
                } else {
                    jQuery(".dialog_warenkorb_msg").html("Der Artikel wurde in den Warenkorb gelegt.");
                }

                let dialog = jQuery("#dialog_warenkorb");
                dialog.dialog({
                    modal: true,
                    open: function (event, ui) {
                        setTimeout("jQuery('#dialog_warenkorb').dialog('close')", setup_dialog_closetime);
                    }
                });
            }
        });

        // Article to sortiment
        jQuery(document.body).on('click', '.article_to_sortiment', function () {
            let art_nr = jQuery(this).attr("data-ez-datalist-artnr") ;  // Article-ID

            let url = '/4DCGI/ezshop?action=meinsortiment_addarticle' + "&sKontaktID=" + modules.getKontaktID() + "&sKontaktKEY=" + modules.getKontaktKey() + "&sTICKCOUNT=" + modules.getTickcount() + `&artnr=jQuery{art_nr}&type=json`;
            let result: any = {};
            /**
		    *
		    * Execute the ajax request
		    */
            jQuery.ajax({
                url: url,
                type: "GET",
                async: false,
                dataType: "json",
                success: function (response: Object) {
                    result = response;
                }.bind(this)
            });

            if (result.status==="success") {
                let dialog = jQuery("#dialog_meinsortiment");
                dialog.dialog({
                    modal: true,
                    open: function (event, ui) {
                        setTimeout("jQuery('#dialog_meinsortiment').dialog('close')", setup_dialog_closetime);
                    }
                });
            }
        });
    }

    public static getAvailibilityHTML(artid:number,basket_quantity:number,art_stockquantity:number, art_vpe:number):string{
        let res:string="";
        let html_onstock=`<span class="article_availability">
        <span class="avail_good has_tooltip">
            <span class="a_tooltip text-center">Auf Lager</span>
            U
        </span>
        </span>`;
        let html_notall=`<span class="article_availability">
        <span class="avail_medium">
            W
        </span>
        </span>
        <span class="text">
            <b>nur [Lagermenge] lieferbar</b>
        </span>`;
        let html_notAvailable = `<span class="article_availability">
            <span class="avail_bad has_tooltip">
                <span class="a_tooltip text-center">Night lieferbar</span>
                V
            </span>
        </span>`;

        if ( art_stockquantity === 0 ) {
            res = html_notAvailable;
        } else {
            if (!basket_quantity)
                basket_quantity=0;
            if (basket_quantity>art_stockquantity)
            {
                res=html_notall.replace("[Lagermenge]",art_stockquantity+"");
            } else
            {
                if (art_vpe>art_stockquantity)
                {

                    res=html_notall.replace("[Lagermenge]",art_stockquantity+"");
                }
                else
                    res=html_onstock;
            }
        }
        return res;
    }

    public static getPriceHTML(preis: string, grundpreis:string):string{
        let res:string="";
        let html_baseprice=`<span class="baseprice">jQuery{grundpreis}&nbsp;€</span>`;
        let html_price=`<span class="actualprice">jQuery{preis}&nbsp;€</span>`;
        let float_preis=parseFloat(preis);
        let float_grundpreis=parseFloat(grundpreis);
        if (float_preis>=float_grundpreis)
        {
            res=html_price;
        }
        else
        {
            res=html_baseprice+html_price;
        }
        return res;
    }

    private static processRefreshPrice( art_id: string, response: IResponseAddToKorb ): void {
        let refreshPriceContainer: Element = document.querySelector("[data-ez-art-price-refresh='"+ art_id +"']");

        // Section: Refresh Price
        let tablerow:string=ControllerBasket.tablerow_price;
        let resHTML:string=tablerow.replace("[grundpreis]",response.grundpreis);
        resHTML=resHTML.replace("[preis]",response.warenkorb_price_txt);
        refreshPriceContainer.innerHTML = resHTML;
        // Section: Refresh Availability
        // TODO: Prüfe -> let refreshAvailContainer: Element = document.querySelector("[data-ez-art-avail-refresh='"+ art_id +"']"); - Abwärtskompatibilität.
        let html_onstock=`<span class="article_availability">
        <span class="avail_good has_tooltip">
            <span class="a_tooltip text-center">Auf Lager</span>
            U
        </span>
        </span>`;
        let html_notall=`<span class="article_availability">
        <span class="avail_medium">
            W
        </span>
        </span>
        <span class="text">
            <b>nur [Lagermenge] lieferbar</b>
        </span>`;
        if (response.added_pieces>response.stockamount)
        {
            let element_avail:HTMLElement=document.getElementById("art-avail-"+response.artid);
            html_notall=html_notall.replace("[Lagermenge]",response.stockamount+"");
            element_avail.innerHTML=html_notall;
        }else
        {
            let element_avail:HTMLElement=document.getElementById("art-avail-"+response.artid);
            element_avail.innerHTML=html_onstock;
        }
    }

    public static processArticleDatalist( response: IResponseAddToKorb, cart_button, Label_addToCart: string, Label_changeAmount: string ): void {
        if ( cart_button ) {
            if (response.added_pieces===0){
                cart_button.innerHTML = Label_addToCart;
                if ( cart_button.classList.contains('secondary') ) {
                    cart_button.classList.remove('secondary');
                }
                let elementMenge:HTMLInputElement=document.getElementById("fvalue"+response.artid) as HTMLInputElement;
                elementMenge.value=response.vpe+"";
                ControllerBasket.Notify.sucess( true, 'Warenkorb', 'Der Artikel wurde aus dem Warenkorb entfernt!', 'top-right', 3 );
            }
            if ( response.added_pieces > 0 ) {
                cart_button.innerHTML = Label_changeAmount;
                if ( !cart_button.classList.contains('secondary') ) {
                    cart_button.classList.add('secondary');
                }
                ControllerBasket.Notify.sucess( true, 'Warenkorb', 'Der Warenkorb wurde aktualisiert!', 'top-right', 3 );
            } else {
                cart_button.innerHTML = Label_addToCart;
                if ( cart_button.classList.contains('secondary') ) {
                    cart_button.classList.remove('secondary');
                }
            }
        }
    }

    public static processArticleListNEB( response: IResponseAddToKorb, art_nr: string, quantity: string, Label_addToCart: string, Label_changeAmount: string ): void {
        let button:JQuery<HTMLElement> = jQuery( "button[data-artid="+ art_nr +"]" );
        let toCartButton: JQuery<HTMLElement> = button.find("div.warenkorb_text");

        if ( response.warenkorb_arts !== undefined ) {
            let output: NodeListOf<HTMLSpanElement> = document.querySelectorAll("#basket_amount");

            for ( var i: number = 0; i < output.length; i++ ) {
                output[i].innerHTML = response.warenkorb_arts.toString();
            }

            if ( quantity == "0" ) {
                toCartButton.html( Label_addToCart );
                button.removeAttr("style");
                button.addClass("submit");
            } else {
                toCartButton.html( Label_changeAmount );

                button.removeClass("submit");
                button.css("margin", "0px");
                button.css("color", "white");
                button.css("background-color", "#EB7E2D");
            }

            if ( currentPageSite == "warenkorb.htm" || currentPageSite == "warenkorb_gast.htm" ) {
                let freight = response.freight; // Frachtkosten
                let sumIncludeTax = response.sum_includetax; // Summe mit Steuern
                let sumNet = response.sum_net; // Summe ohne Steuern
                let sumTaxFull = response.sum_tax_full; // Summe der Steuern (voll)
                let sumTaxHalf = response.sum_tax_half; // Summe der Steuern (halb)

                /**
                 * Get the HTML Elements to Refresh
                 */
                let Element_CartSum: HTMLElement = document.getElementById("cart_sum");
                let Element_freightSum: HTMLElement = document.getElementById("freight_sum");
                let Element_SumNet: HTMLElement = document.getElementById("sum_net");
                let Element_SumTaxFull: HTMLElement = document.getElementById("sum_tax_full");
                let Element_SumTaxHalf: HTMLElement = document.getElementById("sum_tax_half");
                let Element_SumInclTax: HTMLElement = document.getElementById("sum_incl_tax");

                if ( Element_CartSum ) {
                    if ( sWaehrnam == "EUR" ) {
                        Element_CartSum.innerHTML = "&euro;&nbsp;" + sumNet;
                    } else {
                        Element_CartSum.innerHTML = sWaehrnam + "&nbsp;" + sumNet;
                    }
                }

                if ( Element_freightSum ) {
                    if ( sWaehrnam == "EUR" ) {
                        Element_freightSum.innerHTML = "&euro;&nbsp;" + freight;
                    } else {
                        Element_freightSum.innerHTML = sWaehrnam + "&nbsp;" + freight;
                    }
                }

                if ( Element_SumNet ) {
                    if ( sWaehrnam == "EUR" ) {
                        Element_SumNet.innerHTML = "&euro;&nbsp;" + sumNet;
                    } else {
                        Element_SumNet.innerHTML = sWaehrnam + "&nbsp;" + sumNet;
                    }
                }

                if ( Element_SumTaxFull ) {
                    if ( sWaehrnam == "EUR" ) {
                        Element_SumTaxFull.innerHTML = "&euro;&nbsp;" + sumTaxFull;
                    } else {
                        Element_SumTaxFull.innerHTML = sWaehrnam + "&nbsp;" + sumTaxFull;
                    }
                }

                if ( Element_SumTaxHalf ) {
                    if ( sWaehrnam == "EUR" ) {
                        Element_SumTaxHalf.innerHTML = "&euro;&nbsp;" + sumTaxHalf;
                    } else {
                        Element_SumTaxHalf.innerHTML = sWaehrnam + "&nbsp;" + sumTaxHalf;
                    }
                }

                if ( Element_SumInclTax ) {
                    if ( sWaehrnam == "EUR" ) {
                        Element_SumInclTax.innerHTML = "<b>&euro;&nbsp;" + sumIncludeTax + "</b>";
                    } else {
                        Element_SumInclTax.innerHTML = "<b>" + sWaehrnam + "&nbsp;" + sumIncludeTax + "</b>";
                    }
                }
            }
        }
    }

    public static processArticleListVV( quantity: string, element_field_container, element_is_in_warenkorb_status, parent_from_field_container, ele, cart_button_text_in_cart, cart_button_text_add_cart, class_to_add ): void {

        if ( quantity === "0" ) {
            jQuery(ele).html( cart_button_text_add_cart );
            jQuery(ele).removeClass( class_to_add );
            jQuery(element_field_container).removeClass( 'bg-field-primary' );
            jQuery(element_field_container).addClass( 'bg-field-secondary' );

            if ( element_is_in_warenkorb_status == "true" ) {
                element_is_in_warenkorb_status = "false";
                jQuery(element_field_container).attr("data-ez-is-in-warenkorb", "false");

                if ( parent_from_field_container ) {
                    jQuery(parent_from_field_container).removeClass('show-input');
                }
                ControllerBasket.Notify.error( true, 'Warenkorb', 'Artikel wurde vom Warenkorb entfernt!', 'top-right', 3 );
            }
            if ( currentPageSite == "warenkorb.htm" ) {
                window.location.reload();
            }
        } else {
            jQuery(ele).html( cart_button_text_in_cart );
            jQuery(ele).addClass( class_to_add );
            jQuery(element_field_container).removeClass( 'bg-field-secondary' );
            jQuery(element_field_container).addClass('bg-field-primary');

            if ( element_is_in_warenkorb_status == "false" ) {
                element_is_in_warenkorb_status = "true";
                jQuery(element_field_container).attr("data-ez-is-in-warenkorb", "true");

                ControllerBasket.Notify.sucess( true, 'Warenkorb', 'Ein Artikel wurde erfolgreich in den Warenkorb gelegt!', 'top-right', 3 );
            } else if ( element_is_in_warenkorb_status == "true" ) {
                ControllerBasket.Notify.sucess( true, 'Warenkorb', 'Der Warenkorb wurde aktualisiert!', 'top-right', 3 );
            }

            if ( parent_from_field_container ) {
                jQuery(parent_from_field_container).addClass('show-input');
            }
        }

    }
}