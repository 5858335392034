import { Controller } from "../../classes/mvc/Controller";
import { modules } from "../../main";
import { ModelGMaps } from "./ModelGMaps";

declare let ezentrum_session_vars: any;
export class ControllerGMaps extends Controller<ModelGMaps> {

    private outputContainer: HTMLDivElement;
    private useApi: string;

    public constructor( accessName: string, accessID: number, outputContainer: HTMLDivElement ) {
        super( new ModelGMaps(), accessName, accessID );

        this.outputContainer = outputContainer;
    }

    public initGlobals(): void {
        this.useApi = this.getApiToUse();
    }

    public run(): void {
        switch (this.useApi) {
            case "distance":
                this.initDistanceMatrix();
                break;
        }
    }

    private getApiToUse(): string {
        let apiToUse = this.outputContainer.getAttribute("data-ez-param-useApi");
        if ( apiToUse !== null ) {
            return apiToUse;
        } else {
            modules.error("Das Attribut 'data-ez-param-useApi' ist nicht vorhanden!");
            return "";
        }
    }

    private initDistanceMatrix(): void {
        let isButtonRendered: boolean = this.buildCalculateDistanceButton();
        if ( isButtonRendered ) {
            this.assignButtonEvent();
        }

        let customerStreet: string = ezentrum_session_vars.customer.street;
        let customerHouseNumber: string = ezentrum_session_vars.customer.house_number;
        let customerPostcode: string = ezentrum_session_vars.customer.postcode;
        let customerCity: string = ezentrum_session_vars.customer.city;

        let destinationURL: string = this.buildDistanceLink( customerStreet, customerHouseNumber, customerPostcode, customerCity );

        console.log("Link: " + destinationURL);
    }

    private buildCalculateDistanceButton(): boolean {
        let button: HTMLButtonElement = document.createElement("button");
        button.type = "button";
        button.id = this.getModule().getConfig("distanceButtonId");
        button.innerHTML = this.getModule().getLabel("distanceButtonText");

        if ( this.outputContainer ) {
            this.outputContainer.appendChild(button);

            return true;
        } else {
            return false;
        }
    }

    private assignButtonEvent(): void {
        let button = document.getElementById( this.getModule().getConfig("distanceButtonId") );

        if ( button ) {
            button.addEventListener("click", this.calculateDistance.bind(this), false);
        }
    }

    private calculateDistance(event): void {
        // Hindert das der Button das Kassen Form ausführt
        event.preventDefault();

        let testResponse = {
            "destination_addresses": [
                "Mönckebergstraße 1, 20095 Hamburg, Deutschland"
            ],
            "origin_addresses": [
                "Gasstraße 16, 22761 Hamburg, Deutschland"
            ],
            "rows": [
                {
                    "elements": [
                        {
                            "distance": {
                                "text": "7,8 km",
                                "value": 7815
                            },
                            "duration": {
                                "text": "19 Minuten",
                                "value": 1139
                            },
                            "status": "OK"
                        }
                    ]
                }
            ],
            "status": "OK"
        }

        if ( testResponse.status === "OK" ) {
            let distance = testResponse.rows[0];
            let elementDistance = distance.elements[0].distance.text;

            let spanElement = document.createElement("span");
            spanElement.innerText = "Die Entfernung beträgt " + elementDistance;

            this.outputContainer.appendChild(spanElement);
        }
    }

    private buildDistanceLink( street: string, houseNumber: string, postcode: string, city: string ): string {
        let url: string = "";
        
        if ( street !== "" ) {
            url += encodeURI(street) + "+";
        }

        if ( houseNumber !== "" ) {
            url += houseNumber + "+";
        }

        if ( postcode !== "" ) {
            url += postcode + "+";
        }

        if ( city !== "" ) {
            url += city;
        }

        return url;
    }
}