import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Globals, KeyCodes } from "./../../classes/Globals";
import { ModelLightbox } from "./ModelLightbox";
import { Pair } from "./../../libs/Pair";
import { Arrays } from "./../../libs/Arrays";
import { Windows } from "./../../libs/Windows";
import { LightboxItem, ItemType } from "./LightboxItem";
import { Json } from "./../../libs/Json";
import { Elements } from "./../../libs/Elements";
import { IOverlay } from "./../../classes/IOverlay";
import jQuery = require("jquery");

enum Sizes {
    DYNAMIC = "dynamic",
    STATIC_LARGEST_PIC = "static_large",
    STATIC_SMALLEST_PIC = "static_small"
}

export class ControllerLightbox extends HTMLController<ModelLightbox> implements IOverlay {
    private static PADDING: number = 10;
    private static THUMB_SIZE: number = 50;
    private static MIN_SIZE: number = 200;
    private static FULLSCREEN_FACTOR: number = 0.85;

    private static VIDEO_ELEMENT: JQuery<HTMLElement>;
    private static IMAGE_ELEMENT: JQuery<HTMLElement>;

    private lightboxItems: Array<LightboxItem>;

    private currentItem: LightboxItem;
    private showThumbs: boolean;

    private setStaticSize: boolean;
    private staticSize: Pair<number, number>;
    private size: string;

    /**
     * 
     * HTML Structure
     */
    private container: JQuery<HTMLElement>;

    private item: JQuery<HTMLElement>;

    private buttonsContainer: JQuery<HTMLElement>;
    private thumbsContainer: JQuery<HTMLElement>;

    private previousButton: JQuery<HTMLElement>;
    private nextButton: JQuery<HTMLElement>;
    private closeButton: JQuery<HTMLElement>;

    public constructor(accessName: string, accessID: number, element: JQuery<HTMLElement>) {
        super(new ModelLightbox(), accessName, accessID, element);
    }

    public initGlobals(): void {
        var fullscreenFactor = this.getModule().getConfig("fullscreen_factor");
        if (fullscreenFactor != null && fullscreenFactor <= 1) {
            ControllerLightbox.FULLSCREEN_FACTOR = fullscreenFactor;
        }

        var padding = this.getModule().getConfig("padding");
        if (padding != null) {
            ControllerLightbox.PADDING = padding;
        }

        var thumbSize = this.getModule().getConfig("thumb_size");
        if (thumbSize != null) {
            ControllerLightbox.THUMB_SIZE = thumbSize;
        }

        var minSize = this.getModule().getConfig("min_size");
        if (minSize != null) {
            ControllerLightbox.MIN_SIZE = minSize;
        }

        var videoElement = jQuery(this.getModule().getComponent("video"));
        if (videoElement.length) {
            ControllerLightbox.VIDEO_ELEMENT = videoElement;
        } else {
            ControllerLightbox.VIDEO_ELEMENT = jQuery("<iframe></iframe>");;
        }

        var imageElement = jQuery(this.getModule().getComponent("image"));
        if (imageElement.length) {
            ControllerLightbox.IMAGE_ELEMENT = imageElement;
        } else {
            ControllerLightbox.IMAGE_ELEMENT = jQuery("<img>");;
        }
    }

    public run(): void {
        this.lightboxItems = new Array();

        var images: Array<HTMLElement> = Json.convertObjectToArray(this.getItems("image"));
        var videos: Array<HTMLElement> = Json.convertObjectToArray(this.getItems("video"));

        var items: Array<any> = images.concat(videos);

        for (let i = 0; i < items.length; i++) {

            var position = Number(jQuery(items[i]).attr(Globals.PARAMETER_ATTRIBUTE_KEY + "pos"));
            if (!isNaN(position)) {
                position--;
                var positionExists = false;

                for (let j = 0; j < this.lightboxItems.length; j++) {
                    if (this.lightboxItems[j].getID() == position) {
                        positionExists = true;
                        break;
                    }
                }

                if (!positionExists) {
                    var type: string = items[i].attr(Globals.ITEM_ATTRIBUTE_KEY) || ItemType.PICTURE;

                    var element: JQuery<HTMLElement> = items[i];
                    var item: JQuery<HTMLElement> = null;
                    var thumb: JQuery<HTMLElement> = null;

                    var width: number = 0;
                    var height: number = 0;

                    if (type == ItemType.VIDEO) {
                        var src: string = element.attr(Globals.PARAMETER_ATTRIBUTE_KEY + "src");

                        if (src != null) {
                            item = ControllerLightbox.VIDEO_ELEMENT.clone();
                            item.attr("src", src);

                            thumb = jQuery("<div class='thumb'><label pos='" + position + "'><i class='fa fa-video'></i></label></div>");
                        }
                    } else {
                        var image: HTMLImageElement = element.get(0) as HTMLImageElement;
                        var src: string = element.attr("src");

                        width = image.naturalWidth;
                        height = image.naturalHeight;

                        if (src != null && !isNaN(width) && !isNaN(height)) {
                            item = ControllerLightbox.IMAGE_ELEMENT.clone();
                            item.attr("src", src);

                            thumb = jQuery("<div class='thumb'><img src='" + src + "' " + Globals.PARAMETER_ATTRIBUTE_KEY + "pos='" + position + "'></div>");
                        }
                    }

                    this.lightboxItems.push(new LightboxItem(type, position, element, item, thumb));

                    // if ( item != null && thumb != null ){
                    //     var lightboxItem:LightboxItem = new LightboxItem( type, position, element, item, thumb );
                    //     if( lightboxItem.isValid() ){
                    //         this.lightboxItems.push( lightboxItem );
                    //     }
                    // }

                }
            }

        }

        Arrays.sort(this.lightboxItems, LightboxItem.comparatorDescending);

        this.currentItem = this.lightboxItems[0];

        /**
         * 
         * Configurations
         */
        this.size = Sizes.DYNAMIC;
        this.setStaticSize = size == Sizes.STATIC_LARGEST_PIC || size == Sizes.STATIC_SMALLEST_PIC;

        var size = this.getParam("size")
        if (size != null) {
            this.size = size;
            this.setStaticSize = size == Sizes.STATIC_LARGEST_PIC || size == Sizes.STATIC_SMALLEST_PIC;
        }

        this.showThumbs = this.lightboxItems.length > 1;

        /**
         * 
         * Gui
         */
        this.container = jQuery(this.getModule().getComponent("container"));

        this.item = jQuery("<div class='item'></div>");

        this.thumbsContainer = jQuery(this.getModule().getComponent("thumbs.container"));
        this.buttonsContainer = jQuery(this.getModule().getComponent("buttons.container"));

        this.previousButton = jQuery(this.getModule().getComponent("buttons.previous"));
        this.nextButton = jQuery(this.getModule().getComponent("buttons.next"));
        this.closeButton = jQuery(this.getModule().getComponent("buttons.close"));

        if (this.buildStructure()) {

            if (this.showThumbs) {
                this.buildThumbs();
            }

            this.bindEvents();
            this.closePopup();
        } else {
            this.getModule().error(Globals.MODULE_LOADING_ERROR + " nicht alle benötigten Komponenten gefunden wurden");
        }
    }

    private bindEvents(): void {
        for (var i = 0; i < this.lightboxItems.length; i++) {
            jQuery(this.lightboxItems[i].getOpenItem()).on("click", this.openPopup.bind(this, this.lightboxItems[i]));
        }

        if (this.showThumbs) {
            this.previousButton.on("click", this.previousItem.bind(this));
            this.nextButton.on("click", this.nextItem.bind(this));
        }

        this.closeButton.on("click", this.closePopup.bind(this));

        /**
         * 
         * On window resize
         */
        Windows.instance.onResize(this.processCurrentItem.bind(this));

        /**
         * 
         * Key events
         */
        jQuery(document).on("keyup", function (event: KeyboardEvent) {
            // TODO: alte Funktion überschreiben
            switch (event.which) {
                case KeyCodes.RIGHT:
                    if (this.showThumbs) {
                        this.nextItem();
                    }
                    break;
                case KeyCodes.LEFT:
                    if (this.showThumbs) {
                        this.previousItem();
                    }
                    break;
                case KeyCodes.ESC:
                    this.closePopup();
                    break;
            }

        }.bind(this));
    }

    public openPopup(item: LightboxItem = null): void {
        if (item != null && this.setStaticSize) {
            switch (this.size) {
                case Sizes.STATIC_LARGEST_PIC:
                    this.staticSize = this.findLargestItem();
                    break
                case Sizes.STATIC_SMALLEST_PIC:
                    this.staticSize = this.findSmallestItem();
            }

            this.setStaticSize = false;
        }

        Globals.OVERLAY.openOverlay(this.getModule().getName());
        Elements.showElement(this.container);

        this.openItem(item.getID());
    }

    public closePopup(): void {
        Globals.OVERLAY.closeOverlay(this.getModule().getName());
        Elements.hideElement(this.container);
    }

    private openItem(id: number): void {
        if (id >= 0 && id < this.lightboxItems.length) {
            for (let i = 0; i < this.lightboxItems.length; i++) {
                if (this.lightboxItems[i].getID() == id) {
                    this.currentItem = this.lightboxItems[i];
                }
            }
        }

        this.changeItem();
    }

    private changeItem(): void {
        this.item.html("");

        this.item.append(this.currentItem.getItem());
        this.item.addClass(this.currentItem.getType());
        this.item.hide();
        // this.item.on( "ready", function() {
        //     this.processCurrentItem();
        //     this.item.show();
        // }.bind(this));
        this.processCurrentItem();
        this.item.show();
    }

    private previousItem(): void {
        if (this.currentItem.getID() - 1 >= 0) {
            this.currentItem = this.lightboxItems[this.currentItem.getID() - 1];
        } else {
            this.currentItem = this.lightboxItems[this.lightboxItems.length - 1]
        }

        this.changeItem();
    }

    private nextItem(): void {
        if (this.currentItem.getID() + 1 < this.lightboxItems.length) {
            this.currentItem = this.lightboxItems[this.currentItem.getID() + 1];
        } else {
            this.currentItem = this.lightboxItems[0];
        }

        this.changeItem();
    }

    private findLargestItem(): Pair<number, number> {
        var paddingLR: number = ControllerLightbox.PADDING;
        var paddingTB: number = ControllerLightbox.PADDING;

        var result: Pair<number, number> = null;
        var largestPicture: number = 0;

        for (let i = 0; i < this.lightboxItems.length; i++) {
            var itemDimemsion = this.getItemDimension(this.lightboxItems[i]);

            var pictureWidth: number = itemDimemsion.getKey() + (2 * paddingLR);
            var pictureHeight: number = itemDimemsion.getValue() + (2 * paddingTB);

            if (pictureHeight * pictureWidth > largestPicture) {
                largestPicture = pictureHeight * pictureWidth;
                result = new Pair(pictureWidth, pictureHeight);
            }
        }

        return this.checkItemSize(result.getKey(), result.getValue());
    }

    private findSmallestItem(): Pair<number, number> {
        var paddingLR: number = ControllerLightbox.PADDING;
        var paddingTB: number = ControllerLightbox.PADDING;

        var largestPicture = this.findLargestItem();

        var result: Pair<number, number> = null;
        var smallestPicture: number = largestPicture.getKey() * largestPicture.getValue();

        for (let i = 0; i < this.lightboxItems.length; i++) {
            var itemDimemsion = this.getItemDimension(this.lightboxItems[i]);

            var pictureWidth: number = itemDimemsion.getKey() + (2 * paddingLR);
            var pictureHeight: number = itemDimemsion.getValue() + (2 * paddingTB);

            if (pictureHeight * pictureWidth < smallestPicture) {
                smallestPicture = pictureHeight * pictureWidth;
                result = new Pair(pictureWidth, pictureHeight);
            }
        }

        return this.checkItemSize(result.getKey(), result.getValue());
    }

    private processCurrentItem(): void {
        var paddingLR: number = ControllerLightbox.PADDING;
        var paddingTB: number = ControllerLightbox.PADDING;

        var itemDimemsion = this.getItemDimension(this.currentItem);
        var pictureWidth: number = itemDimemsion.getKey() + (2 * paddingLR);
        var pictureHeight: number = itemDimemsion.getValue() + (2 * paddingTB);

        var newWidth: number = pictureWidth;
        var newHeight: number = pictureHeight;

        if (this.size == Sizes.STATIC_SMALLEST_PIC || this.size == Sizes.STATIC_LARGEST_PIC) {

            var staticWidth: number = this.staticSize.getKey();
            var staticHeight: number = this.staticSize.getValue();

            newWidth = staticWidth;
            newHeight = staticHeight;

            if (this.size == Sizes.STATIC_SMALLEST_PIC) {
                var sizesWithoutPadding = this.checkItemSize(pictureWidth, pictureHeight, staticWidth, staticHeight);

                paddingLR += (staticWidth - sizesWithoutPadding.getKey()) / 2;
                paddingTB += (staticHeight - sizesWithoutPadding.getValue()) / 2;
            } else if (this.size == Sizes.STATIC_LARGEST_PIC) {
                paddingLR += (newWidth - pictureWidth) / 2;
                paddingTB += (newHeight - pictureHeight) / 2;
            }

        } else {
            var newSizes = this.checkItemSize(pictureWidth, pictureHeight);

            newWidth = newSizes.getKey();
            newHeight = newSizes.getValue();
        }

        /**
         * 
         * Check if the image is smaller than the minimum size
         */
        if (newWidth < ControllerLightbox.MIN_SIZE) {
            paddingLR += (ControllerLightbox.MIN_SIZE - newWidth) / 2;
            newWidth += ControllerLightbox.MIN_SIZE - newWidth;
        }
        if (newHeight < ControllerLightbox.MIN_SIZE) {
            paddingTB += (ControllerLightbox.MIN_SIZE - newHeight) / 2;
            newHeight += ControllerLightbox.MIN_SIZE - newHeight;
        }

        /**
        * 
        * Check if the container overlaps with the thumbs
        */
        if (this.showThumbs && (window.innerHeight - newHeight) / 2 < ControllerLightbox.THUMB_SIZE) {
            var difference: number = ControllerLightbox.THUMB_SIZE - ((window.innerHeight - newHeight) / 2);
            var puffer: number = 20;

            newHeight -= (difference + puffer) * 2;
            newWidth = this.scalePicture(pictureHeight, newHeight, pictureWidth);
        }

        /**
        * 
        * Set the css styles
        */
        this.item.css("padding", paddingTB + "px " + paddingLR + "px");
        this.item.css("width", "100%");
        this.item.css("height", "100%");

        this.container.css("width", newWidth);
        this.container.css("height", newHeight);
    }

    private checkItemSize(pictureWidth: number, pictureHeight: number, windowWidth = window.innerWidth * ControllerLightbox.FULLSCREEN_FACTOR, windowHeight = (window.innerHeight - (this.showThumbs ? ControllerLightbox.THUMB_SIZE : 0)) * ControllerLightbox.FULLSCREEN_FACTOR): Pair<number, number> {
        var newWidth: number = pictureWidth;
        var newHeight: number = pictureHeight;

        /**
         * 
         * Check if the image is larger than the screen
         */
        if (pictureWidth > windowWidth || pictureHeight > windowHeight) {

            /**
             * 
             * Check if both picture width and height are large than the screen
             */
            if (pictureWidth > windowWidth && pictureHeight > windowHeight) {

                newWidth = windowWidth;
                newHeight = windowHeight;

                if (newHeight > newWidth) {
                    newHeight = this.scalePicture(pictureWidth, newWidth, pictureHeight);
                } else if (newWidth > newHeight) {
                    newWidth = this.scalePicture(pictureHeight, newHeight, pictureWidth);
                }

            } else if (pictureWidth > windowWidth) {
                newWidth = windowWidth;
                newHeight = this.scalePicture(pictureWidth, newWidth, pictureHeight);
            } else if (pictureHeight > windowHeight) {
                newHeight = windowHeight;
                newWidth = this.scalePicture(pictureHeight, newHeight, pictureWidth);
            }
        }

        return new Pair(newWidth, newHeight);
    }

    private scalePicture(originalX: number, newX: number, oldY: number): number {
        var differenceInPX: number = Math.abs(originalX - newX);
        var differenceInPercentage: number = differenceInPX / (originalX / 100);

        return oldY - ((oldY / 100) * differenceInPercentage);
    }

    private getItemDimension(item: LightboxItem): Pair<number, number> {
        return new Pair(item.getWidth(), item.getHeight());
    }

    /**
     * 
     * Build the GUI
     */
    private buildThumbs(): void {
        for (var i = 0; i < this.lightboxItems.length; i++) {
            var item: LightboxItem = this.lightboxItems[i];
            var thumb: JQuery<HTMLElement> = item.getThumb();

            this.thumbsContainer.append(thumb);

            thumb.css("max-height", ControllerLightbox.THUMB_SIZE);
            thumb.css("max-width", ControllerLightbox.THUMB_SIZE);
            thumb.on("click", this.openItem.bind(this, item.getID()));
        }
    }

    private buildStructure(): boolean {
        var result: boolean = false;

        if (this.container.length) {

            jQuery("body").append(this.container);

            if (this.container.find(".inner").length && this.item.length && this.thumbsContainer.length && this.buttonsContainer.length) {

                this.container.find(".inner").append(this.item);
                this.container.find(".inner").append(this.thumbsContainer);
                this.container.find(".inner").append(this.buttonsContainer);

                if (this.previousButton.length && this.nextButton.length && this.closeButton.length) {

                    if (this.showThumbs) {
                        this.buttonsContainer.append(this.previousButton);
                        this.buttonsContainer.append(this.nextButton);
                    }

                    this.buttonsContainer.append(this.closeButton);

                    result = true;

                }

            }

        }

        return result;
    }

}