import { Controller } from "./../../classes/mvc/Controller";
import { Globals } from "./../../classes/Globals";
import { ModelAutocomplete } from "./ModelAutocomplete";
import { Elements } from "./../../libs/Elements";
import { Json } from "./../../libs/Json";
import { modules } from "../../main";
import { KeyCodes } from "./../../classes/Globals";
import jQuery = require( "jquery" );
import { ClickEvent } from "../../classes/ClickEvent";
import { Modules } from "../../Modules";

declare var ezentrum_variables:any;

export class ControllerAutocomplete extends Controller<ModelAutocomplete>{

    public constructor ( accessName:string, accessID:number, inputField:JQuery<HTMLElement>, outputContainer:JQuery<HTMLElement>, submitButton:JQuery<HTMLElement> ){
		super( new ModelAutocomplete(), accessName, accessID );
		
		this.inputField = inputField;
		this.outputContainer = outputContainer;
		this.submitButton = submitButton;
	}

	private static SEARCH_URL:string = "";

    private static TARGET_URL_QUERY:string = "";
	private static TARGET_URL_BRAND:string = "";

	private static HEADLINE_RECOMMENDATIONS:string = "";
	private static HEADLINE_BRANDS:string = "";
	private static HEADLINE_CONTENTS:string = "";
	private static HEADLINE_KEYWORDS:string = "";
	private static HEADLINE_PRODUCTS:string = "";
	private static HEADLINE_CATEGORIES:string = "";

	private static INPUT_PLACEHOLDER:string = "";

	private static SEARCH_ENGINE_ID:number = -1;

	private static SEARCH_BY_PRODUCTS:boolean = false;
    private static SEARCH_BY_KEYWORDS:boolean = false;
    private static SEARCH_BY_CONTENTS:boolean = false;
    private static SEARCH_BY_BRANDS:boolean = false;
	private static SEARCH_BY_RECOMMENDATIONS:boolean = false;
	private static SEARCH_BY_CATEGORIES:boolean = false;

	private static BUILD_SEARCH_URL:boolean = false;
	private static PRODUCTS_RANKSORT:boolean = false;

	private static SHARP_SEARCH:boolean = false;
	private static PROCESS_VIEWTYPE: number = 1;
	private static BUILD_BY_ORDER:boolean = false;
	private static PROCESS_IN_HEAD_CONTAINER: boolean = false;
	private static MAX_SHOW_THUMBS: number = 4;

	private CASPARDO_QUERY:string;

    private timer:any;
	private interval:number;
	
    private currentSelection:number;
    private currentURL:string;
    private currentKeyword:string;
	private currentTrackingType:string;
	private currentQueryWord:string = "";

	private inputField:JQuery<HTMLElement>;
	private outputContainer:JQuery<HTMLElement>;
	private submitButton:JQuery<HTMLElement>;

	public initGlobals ():void{
		// var targetUrl = this.getModule().getConfig( "target_url" ) + "&sKontaktID="+ modules.getKontaktID() +"&sKontaktKEY="+ modules.getKontaktKey() +"&sTICKCOUNT="+ modules.getTickcount();
		var targetUrl = this.getModule().getConfig( "target_url" ) + "&hid=" + modules.getGlobalConfig( "mandant_id") + "&sprachnr=" + modules.getLanguageCode() + "&enter=caspardo";
		var search_engine_id = Number( modules.getGlobalConfig( "caspardo_language_mapping." + modules.getLanguageCode() ) );
		var searchAutocomplete = this.getModule().getConfig( "search_autocomplete" );

		this.getModule().addView( "search_url", this.getModule().getConfig( "search_url" ) );
		// this.getModule().addView( "tracking_url", this.getModule().getConfig( "tracking_url" ) );

		ControllerAutocomplete.SEARCH_ENGINE_ID = search_engine_id;

		ControllerAutocomplete.SEARCH_URL = this.processOne( "search_url", "id", String( ControllerAutocomplete.SEARCH_ENGINE_ID ) );

		ControllerAutocomplete.TARGET_URL_QUERY = targetUrl + "&query=";
		ControllerAutocomplete.TARGET_URL_BRAND = targetUrl + "&brand=";

		ControllerAutocomplete.SEARCH_BY_PRODUCTS = searchAutocomplete.products == true;
		ControllerAutocomplete.SEARCH_BY_KEYWORDS = searchAutocomplete.keywords == true;

		ControllerAutocomplete.SEARCH_BY_CONTENTS = searchAutocomplete.content == true;
		ControllerAutocomplete.SEARCH_BY_BRANDS = searchAutocomplete.brands == true;
		ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS = searchAutocomplete.recommendation == true;
		ControllerAutocomplete.SEARCH_BY_CATEGORIES = searchAutocomplete.categories == true;

		ControllerAutocomplete.BUILD_SEARCH_URL = searchAutocomplete.build_link == true;
		ControllerAutocomplete.PRODUCTS_RANKSORT = searchAutocomplete.products_ranksort == true;

		ControllerAutocomplete.SHARP_SEARCH = searchAutocomplete.sharp_search == true;

		let processViewtype = this.getModule().getConfig( "viewtype" );
		if ( processViewtype !== null ) {
			ControllerAutocomplete.PROCESS_VIEWTYPE = processViewtype;
		}

		let buildByOrder = this.getModule().getConfig( "build_by_order" );
		if ( buildByOrder !== null ) {
			ControllerAutocomplete.BUILD_BY_ORDER = buildByOrder;
		}

		let processInHeadContainer = this.getModule().getConfig( "process_in_head_container" );
		if ( processInHeadContainer !== null ) {
			ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER = processInHeadContainer;
		}

		let maxShownThumbs = this.getModule().getConfig( "showThumbs" );
		if ( maxShownThumbs !== null ) {
			ControllerAutocomplete.MAX_SHOW_THUMBS = maxShownThumbs;
		}

		ControllerAutocomplete.HEADLINE_RECOMMENDATIONS = this.getModule().getLabel( "headline_recommendations" );
		ControllerAutocomplete.HEADLINE_BRANDS = this.getModule().getLabel( "headline_brands" );
		ControllerAutocomplete.HEADLINE_CONTENTS = this.getModule().getLabel( "headline_contents" );
		ControllerAutocomplete.HEADLINE_KEYWORDS = this.getModule().getLabel( "headline_keywords" );
		ControllerAutocomplete.HEADLINE_PRODUCTS = this.getModule().getLabel( "headline_products" );
		ControllerAutocomplete.HEADLINE_CATEGORIES = this.getModule().getLabel( "headline_categories" );

		ControllerAutocomplete.INPUT_PLACEHOLDER = this.getModule().getLabel( "input_placeholder" );

		this.setViews();
	}

	private setViews ():void{
        var container = this.getModule().getComponent( "container" );
        if ( container != null ){
            this.getModule().addView( "container", container );
        }

        if ( ControllerAutocomplete.SEARCH_BY_PRODUCTS ){
            var rowProducts= this.getModule().getComponent( "row_products" );
            if ( rowProducts != null ){
                this.getModule().addView( "row_products", rowProducts );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_BRANDS ){
            var rowBrands= this.getModule().getComponent( "row_brands" );
            if ( rowBrands != null ){
                this.getModule().addView( "row_brands", rowBrands );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_CONTENTS ){
            var rowContents= this.getModule().getComponent( "row_contents" );
            if ( rowContents != null ){
                this.getModule().addView( "row_contents", rowContents );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_KEYWORDS ){
            var rowKeywords= this.getModule().getComponent( "row_keywords" );
            if ( rowKeywords != null ){
                this.getModule().addView( "row_keywords", rowKeywords );
            }
        }

        if ( ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS ){
            var rowRecommendations= this.getModule().getComponent( "row_recommendations", false );
            if ( rowRecommendations != null ){
                this.getModule().addView( "row_recommendations", rowRecommendations );
            }
		}

		if ( ControllerAutocomplete.BUILD_SEARCH_URL ) {
			var rowBuildURL = this.getModule().getComponent( "row_buildUrl" );
			if ( rowBuildURL != null ) {
				this.getModule().addView( "row_buildUrl", rowBuildURL);
			}
		}

		if ( ControllerAutocomplete.SEARCH_BY_CATEGORIES ) {
			var rowBuildCategories = this.getModule().getComponent( "row_categories" );
			if ( rowBuildCategories != null ) {
				this.getModule().addView( "row_categories", rowBuildCategories );
			}
		}

		if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER ) {
			var rowHeadContainer = this.getModule().getComponent( "head_container" );
			if ( rowHeadContainer != null ) {
				this.getModule().addView( "head_container", rowHeadContainer );
			}
		}
    }

    public run ():void{
		this.timer = 0;
		this.interval = 500;
		
		this.currentSelection;
		this.currentURL = "";
		this.currentKeyword = "";
		// this.currentTrackingType = "keyword";

		/**
		*
		* Check if the elements exists
		*/
		if ( this.inputField.length && this.outputContainer.length ) {
			if ( ControllerAutocomplete.SEARCH_URL!= null ){

				this.inputField.attr( "placeholder", ControllerAutocomplete.INPUT_PLACEHOLDER );
				this.assign_static_events();

				this.setKeyword( "" );
				this.outputContainer.attr( Globals.ATTRIBUTE_PREFIX + "open", "false" );

				/**
				*
				* Check if the query exists
				*/
				if ( typeof ezentrum_variables !== 'undefined' ) {
					this.CASPARDO_QUERY = ezentrum_variables.T_caspardo_query;
				} else {
					this.CASPARDO_QUERY = Modules.modulesVariables.T_caspardo_query;
				}
				
				if ( typeof this.CASPARDO_QUERY !== "undefined" && this.CASPARDO_QUERY != "" ) {
					this.setKeyword( this.CASPARDO_QUERY );
				}

			} else{
				this.getModule().error( Globals.MODULE_LOADING_ERROR + " keine Search URL angegeben wurde");
			}
		} else {
			this.getModule().error( Globals.MODULE_LOADING_ERROR + " die HTML Elemente mit den folgenden IDs nicht gefunden wurde: search_input und search_output");
		}
    }

	/**
	*
	* Set the current query
	*/
	public setKeyword  ( keyword:string ):void{
		this.currentKeyword = keyword;

		this.inputField.val( this.currentKeyword );
		this.currentURL = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent ( this.currentKeyword );
		
		this.start( false );
	}

	/**
	*
	* Get the current query
	*/
	private get_keyword ():string{
		return this.currentKeyword;
	}

	// private assign_content_change_events  ():void{
	// 	var items = this.outputContainer.find( "li["+ Globals.ATTRIBUTE_PREFIX + "search-item-type] a" );
	// 		// items.each( function ( i:number ){
	// 		// 	jQuery( items[ i ] ).on( "click", function ( event:any ){

	// 		// 		this.currentTrackingType = jQuery( event.target.closest("["+ Globals.ATTRIBUTE_PREFIX + "search-item-type]") ).attr( Globals.ATTRIBUTE_PREFIX + "search-item-type" ) || null;
	// 		// 		// this.onExecute();

	// 		// 	}.bind(this));
	// 		// }.bind(this));
	// }

	private assign_static_events ():void{
		let outputMode = this.outputContainer.attr( "data-ez-search-output-mode" );
		/**
		 * 
		 * Input events
		 */
		this.inputField.on( "input", this.start.bind( this, true ) );

		/**
		 * 
		 * Key events
		 */
		this.inputField.on( "keydown", this.keyEvents.bind(this) );
		this.outputContainer.on( "keydown", this.keyEvents.bind(this) );

		/**
		 * 
		 * Execute search events
		 */
		if (this.submitButton.length){
			this.submitButton.on( "click", this.executeSearch.bind(this) );
		}

		/**
		 * 
		 * Open and close the content area
		 */
		jQuery( document ).on("click", function( event:Event ) {
			var clicked_on_output_container = jQuery( event.target ).parents("#search_output").length > 0 || jQuery( event.target ) == this.outputContainer;
			var clicked_on_input = event.target === this.inputField[0];

			if ( !clicked_on_output_container && !clicked_on_input ) {
				Elements.hideElement( this.outputContainer );

				if ( outputMode !== null && outputMode == "mobile" ) {
					this.setNewMobileView( "close" );
				}
			}
		}.bind(this));

		this.inputField.on("focusin", function (){
			let inputVal:string=this.inputField.val();
			if ( inputVal != "" ) {
				if ( outputMode !== null && outputMode == "mobile" ) {
					this.setNewMobileView( "show" );
				}
				
				Elements.showElement( this.outputContainer );
			}
		}.bind(this));

		window.addEventListener("resize", function() {
			let isMobileSearchOpen = this.outputContainer.attr( "data-ez-open" );
			if ( window.innerWidth >= 1200) {
				if ( outputMode !== null && outputMode == "mobile" ) {
					this.setNewMobileView( "close" );
				}
			}
			if ( window.innerWidth < 1200 ) {
				if ( outputMode !== null && outputMode == "mobile" ) {
					if ( isMobileSearchOpen == "true" ) {
						this.setNewMobileView( "show" );
					}
				}
			}
		}.bind(this));
	}

	private setNewMobileView( status: string = "" ): void {
		let overlayElement = document.querySelector( ".mobile_search_overlay" );

		if ( overlayElement ) {
			if ( status === "show" ) {
				overlayElement.classList.add( "d-block" );
				document.body.classList.add( "no-scroll" );
			}

			if ( status === "close" ) {
				overlayElement.classList.remove( "d-block" );
				document.body.classList.remove( "no-scroll" );
			}
		}
	}

	private keyEvents( event:KeyboardEvent ):void {
		switch( event.which ) {
			case KeyCodes.UP:
				this.selection_previous_item();
				event.preventDefault();
			break;

			case KeyCodes.DOWN:
				this.selection_next_item();
				event.preventDefault();
			break;

			case KeyCodes.ENTER:
				this.executeSearch();
				event.preventDefault();
			break;
		}
	}

	private executeSearch ():void{
		if ( this.currentURL != "" ) {
			// this.onExecute();

			window.location.href = this.currentURL;
		}
	}

	private start ( auto_display:boolean ):void{
		this.currentKeyword = this.inputField.val() as string;
		this.currentURL = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent ( this.currentKeyword );

		if (this.currentKeyword !== "") {
			clearTimeout( this.timer );

			this.timer = setTimeout( function(){
				/**
				*
				* Get the search results
				*/

				var searchURL = ControllerAutocomplete.SEARCH_URL+ "&query=" + encodeURIComponent( this.currentKeyword ) + "&output=json&language=" + modules.getLanguageCode();

				if ( ControllerAutocomplete.PRODUCTS_RANKSORT ) {
					searchURL += "&ranksort=1";
				}

				var search_results:Object = this.getModel().getSearchResults( searchURL );
				if ( search_results != null ) {

					/**
					*
					* Process the search results and output them
					*/
					if ( ControllerAutocomplete.BUILD_BY_ORDER ) {
						this.printSearchResults( this.process_search_by_order( search_results ), auto_display );
					} else {
						if ( ControllerAutocomplete.PROCESS_VIEWTYPE === 2 ) {
							this.printSearchResults( this.process_search_resultsV2( search_results ), auto_display );
						} else {
							this.printSearchResults( this.process_search_results( search_results ), auto_display );
						}
					}
					
					//this.onContentChange();
					ClickEvent.init();
				}

				let outputMode = this.outputContainer.attr( "data-ez-search-output-mode" );
				if ( outputMode !== null && outputMode == "mobile" ) {
					if ( window.innerWidth >= 1200) {
						// do nothing
						// verhindert, dass in der Suchergebnis Seite am Anfang das Overlay zu sehen ist,
						// da der Autocomplete in der Suche automatisch gestartet wird.
					} else {
						this.setNewMobileView( "show" );
					}
				}

			}.bind(this), this.interval);
		}
	}

	/**************************************** PRINT ****************************************/

	private printSearchResults  ( html_output:string, auto_display:boolean ):void{
	
		if ( html_output != "" ) {
			this.outputContainer.html( html_output );
		} else {
			this.outputContainer.html( "" );
		}

		if ( auto_display ) {

			if ( this.outputContainer.html() != "" ) {
				this.outputContainer.attr( Globals.ATTRIBUTE_PREFIX + "open", "true" );
			} else {
				this.outputContainer.attr( Globals.ATTRIBUTE_PREFIX + "open", "false" );
			}

		}

		this.currentSelection = -1;

		if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER && html_output !== "" ) {
			this.assignThumbEvents();
			if ( ControllerAutocomplete.BUILD_SEARCH_URL ) {
				this.buildShowMoreButton( "", "", this.currentQueryWord );
			} else {
				this.buildShowMoreButton( "", "" );
			}
			this.assignOpenSearchContainersEvents();
		}
	}

	/**************************************** PROCESS RESULTS ****************************************/
	private process_search_results  ( search_results:Object ):string{
		if ( typeof search_results !== undefined ) {

			var html_output = "";

			if ( ControllerAutocomplete.BUILD_SEARCH_URL ) {
				var result = this.processBuildURL( Json.getSubobject( search_results, "autocomplete.searchword" ) );
				html_output += ( result != null ? result : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_BRANDS ) {
				var items = this.processBrands( Json.getSubobject( search_results, "autocomplete.brand.branditem" ),Json.getSubobject( search_results, "autocomplete.searchword" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_CATEGORIES ) {
				var items = this.processCategories( Json.getSubobject( search_results, "autocomplete.categories.category" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_KEYWORDS ) {
				var items = this.processKeywords( Json.getSubobject( search_results, "autocomplete.keywords.keyworditem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_PRODUCTS ) {
				var items = this.processProducts( Json.getSubobject( search_results, "autocomplete.products.productnameitem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS ) {
				var items = this.processRecommendations( Json.getSubobject( search_results, "autocomplete.recommendation.entry" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_CONTENTS ) {
				var items = this.processContents( Json.getSubobject( search_results, "autocomplete.contentall.item" ) );
				html_output += ( items != null ? items : "" );
			}
			
			return html_output;
		} else {
			return null;
		}
	}

	private process_search_by_order ( search_results:Object ):string {
		if ( typeof search_results !== undefined ) {
			let build_order: Array<String> = this.getModule().getConfig( "autocomplete_build_order" );

			var html_output: string = "";
			var articles_output: string = "";
			var moreInformation_output: string = "";
			var recommendations_output: string = "";
			var result: string = "";

			this.currentQueryWord = Json.getSubobject( search_results, "autocomplete.searchword" );

			for ( var i:number = 0; i < build_order.length; i++ ) {

				switch (build_order[i]) {
					case "search_url":
						if ( ControllerAutocomplete.BUILD_SEARCH_URL ) {
							result = this.processBuildURL( Json.getSubobject( search_results, "autocomplete.searchword" ) );
							html_output += ( result != null ? result : "" );
						}
						break;
					case "categories":
						if ( ControllerAutocomplete.SEARCH_BY_CATEGORIES ) {
							result = this.processCategories( Json.getSubobject( search_results, "autocomplete.categories.category" ) );
							html_output += ( result != null ? result : "" );
						}
						break;
					case "keywords":
						if ( ControllerAutocomplete.SEARCH_BY_KEYWORDS ) {
							result = this.processKeywords( Json.getSubobject( search_results, "autocomplete.keywords.keyworditem" ) );
							html_output += ( result != null ? result : "" );
						}
						break;
					case "products":
						if ( ControllerAutocomplete.SEARCH_BY_PRODUCTS ) {
							result = this.processProducts( Json.getSubobject( search_results, "autocomplete.products.productnameitem" ) );
							if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER ) {
								articles_output += ( result != null ? result : "" );
							} else {
								html_output += ( result != null ? result : "" );
							}
						}
						break;
					case "brands":
						if ( ControllerAutocomplete.SEARCH_BY_BRANDS ) {
							result = this.processBrands( Json.getSubobject( search_results, "autocomplete.brand.branditem" ),Json.getSubobject( search_results, "autocomplete.searchword" ) );
							html_output += ( result != null ? result : "" );
						}
						break;
					case "recommendations":
						if ( ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS ) {
							result = this.processRecommendations( Json.getSubobject( search_results, "autocomplete.recommendation.entry" ) );
							if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER ) {
								recommendations_output += ( result != null ? result : "" );
							} else {
								html_output += ( result != null ? result : "" );
							}
						}
						break;
					case "contents":
						if ( ControllerAutocomplete.SEARCH_BY_CONTENTS ) {
							result = this.processContents( Json.getSubobject( search_results, "autocomplete.contentall.item" ) );
							if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER ) {
								moreInformation_output += ( result != null ? result : "" );
							} else {
								html_output += ( result != null ? result : "" );
							}
						}
						break;
				}

			}

			if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER ) {
				var container = this.processHeadContainer( html_output, articles_output, moreInformation_output, recommendations_output );
				html_output = ( container != null ? container : "" );
			}


			return html_output;
		} else {
			return null;
		}
	}

	private process_search_resultsV2( search_results:Object ):string {
		if ( typeof search_results !== undefined ) {

			var html_output = "";

			if ( ControllerAutocomplete.BUILD_SEARCH_URL ) {
				var result = this.processBuildURL( Json.getSubobject( search_results, "autocomplete.searchword" ) );
				html_output += ( result != null ? result : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_PRODUCTS ) {
				var items = this.processProducts( Json.getSubobject( search_results, "autocomplete.products.productnameitem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_CATEGORIES ) {
				var items = this.processCategories( Json.getSubobject( search_results, "autocomplete.categories.category" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_KEYWORDS ) {
				var items = this.processKeywords( Json.getSubobject( search_results, "autocomplete.keywords.keyworditem" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_BRANDS ) {
				var items = this.processBrands( Json.getSubobject( search_results, "autocomplete.brand.branditem" ),Json.getSubobject( search_results, "autocomplete.searchword" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_RECOMMENDATIONS ) {
				var items = this.processRecommendations( Json.getSubobject( search_results, "autocomplete.recommendation.entry" ) );
				html_output += ( items != null ? items : "" );
			}

			if ( ControllerAutocomplete.SEARCH_BY_CONTENTS ) {
				var items = this.processContents( Json.getSubobject( search_results, "autocomplete.contentall.item" ) );
				html_output += ( items != null ? items : "" );
			}
			
			return html_output;
		} else {
			return null;
		}
	}

	private processCategories( categories:any ): string {
		if ( categories != null ) {
			categories = Json.convertObjectToArray( categories );

			var output = "";

			if ( categories.length > 0 ) {
				for (var i = 0; i < categories.length; i++) {
					var currentCategoryName: string = categories[i].name;
					var currentKeyword: string = this.currentKeyword.toLocaleLowerCase();
					var modelID = this.getModel().new();

					if ( ControllerAutocomplete.SHARP_SEARCH ) {
						if ( currentCategoryName.toLocaleLowerCase().includes( currentKeyword ) ) {
							var link = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent( currentCategoryName ) + "&category1=" + encodeURIComponent( currentCategoryName );
	
							this.getModel().add( modelID, "link", link );
							this.getModel().add( modelID, "queryword", currentCategoryName );
						}
					} else {
						var link = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent( currentCategoryName ) + "&category1=" + encodeURIComponent( currentCategoryName );
	
						this.getModel().add( modelID, "link", link );
						this.getModel().add( modelID, "queryword", currentCategoryName );
					}

					if ( ControllerAutocomplete.PROCESS_IN_HEAD_CONTAINER ) {
						this.getModel().add( modelID, "render_button", true );
					} else {
						this.getModel().add( modelID, "render_button", false );
					}

					output += this.process( modelID, "row_categories" );
				}

				if (output == "") {
					return null;
				} else {
					return this.processRow( output, ControllerAutocomplete.HEADLINE_CATEGORIES, "categories", false );
				}
				
			}
		} else {
			return null;
		}
	}

	private processBuildURL( searchword:string ): string {
		if ( searchword != null && searchword != "" ) {
			var output = "";
			var modelID = this.getModel().new();

			this.getModel().add( modelID, "querylink", ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent( searchword ));
			this.getModel().add( modelID, "queryword", searchword );

			output += this.process(modelID, "row_buildUrl");

			return output;
		} else {
			return null;
		}
	}

	private processRecommendations ( recommendations:any ):string{
		if ( recommendations != null ) {
			recommendations = Json.convertObjectToArray( recommendations );
            
            var output = "";

			if ( recommendations.length > 0 ) {

				for (var i = 0; i < recommendations.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", recommendations[i].link );
                    this.getModel().add( modelID, "title", recommendations[i].title );
					
					output += this.process( modelID, "row_recommendations" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_RECOMMENDATIONS, "recommendations", false );
			}
		} else {
			return null;
		}
	}
	private processBrands ( brands:any , searchword:string):string{
		if ( brands != null ) {
			brands = Json.convertObjectToArray( brands );

			var output = "";

			if ( brands.length > 0 ) {
				for (var i = 0; i < brands.length; i++) {
					var modelID = this.getModel().new();
					var brandName = encodeURIComponent( brands[i].brandname );
                    this.getModel().add( modelID, "link", ControllerAutocomplete.TARGET_URL_BRAND + brandName+"&query="+ encodeURIComponent( searchword));
                    this.getModel().add( modelID, "name", brands[i].brandname );
					output += this.process( modelID, "row_brands" );
                }
				return this.processRow( output, ControllerAutocomplete.HEADLINE_BRANDS, "brands", false );
			}

		} else {
			return null;
		}
	}
	private processContents ( contents:any ):string{
		if ( contents != null ) {
			contents = Json.convertObjectToArray( contents );

			var output = "";

			if ( contents.length > 0 ) {
				for (var i = 0; i < contents.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", contents[i].url );
                    this.getModel().add( modelID, "title", contents[i].title );
					this.getModel().add( modelID, "content", contents[i].content );
					this.getModel().add( modelID, "score", contents[i].score );
					this.getModel().add( modelID, "documentType", contents[i].documentType );
					
					output += this.process( modelID, "row_contents" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_CONTENTS, "contents", false );
			}

		} else {
			return null;
		}
	}
	private processKeywords ( keywords:any ):string{
		if ( keywords != null ) {
			keywords = Json.convertObjectToArray( keywords );

			var output = "";

			if (keywords.length > 0 ) {
				for (var i = 0; i < keywords.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", ControllerAutocomplete.TARGET_URL_QUERY +  encodeURIComponent( keywords[i].queryword ) );
                    this.getModel().add( modelID, "queryword", keywords[i].queryword );
					
					output += this.process( modelID, "row_keywords" );
				}

				return this.processRow( output, ControllerAutocomplete.HEADLINE_KEYWORDS, "keywords", false );
			}

		} else {
			return null;
		}
	}

	private processProducts ( products:any ):string{
		if ( products != null ) {
				products = Json.convertObjectToArray( products );

				var output = "";

			if ( products.length > 0 ) {
				for (var i = 0; i < products.length; i++) {
					var modelID = this.getModel().new();

                    this.getModel().add( modelID, "link", products[i].productlink );
                    this.getModel().add( modelID, "articlenumber", products[i].productarticlenumber );
                    this.getModel().add( modelID, "name", products[i].productname );
                    this.getModel().add( modelID, "picture", products[i].productthumbnail );
					this.getModel().add( modelID, "brand", products[i].productbrand );
					this.getModel().add( modelID, "rank", products[i].productrank );
					
					output += this.process( modelID, "row_products" );
                }
				
				return this.processRow( output, ControllerAutocomplete.HEADLINE_PRODUCTS, "products", true );
			}
			
		} else {
			return null;
		}
	}

	private processThumbResults( products, type: string, clickedThumb: string ): string {
		if ( products.length > 0 ) {
			let output: string = "";

			for ( var i = 0; i < products.length; i++ ) {
				let modelID: number = this.getModel().new();

				this.getModel().add( modelID, "link", products[i].url )
				this.getModel().add( modelID, "articlenumber", products[i].articlenumber );
				this.getModel().add( modelID, "name", products[i].title );
				this.getModel().add( modelID, "picture", products[i].picture );
				this.getModel().add( modelID, "brand", products[i].brand );
				this.getModel().add( modelID, "rank", products[i].rank );

				output += this.process( modelID, "row_products" );
			}

			let headline = "";

			// switch (type) {
			// 	case "category":
			// 		headline = this.getModule().getLabel("category_search_text");
			// 		break;
			// 	case "brand":
			// 		headline = this.getModule().getLabel("brand_search_text");
			// 		break;
			// 	case "keyword":
			// 		headline = this.getModule().getLabel("keyword_search_text");
			// 		break;
			// }
			headline = this.getModule().getLabel( "headline_products" );

			headline += clickedThumb;

			return this.processRow( output, headline, "top_article", false );
		}
	}

	private processRow ( output:string, headline:string, category: string = "", showQueryWord: boolean = true ):string{
		var modelID = this.getModel().new();

		this.getModel().add( modelID, "headline", headline );
		this.getModel().add( modelID, "content", output );
		this.getModel().add( modelID, "category", category );

		if ( showQueryWord ) {
			this.getModel().add( modelID, "queryword", this.currentQueryWord );
		}

		return this.process( modelID, "container" );
	}

	private processHeadContainer( output:string, articles: string = "", content: string = "", recommendations: string = "" ): string {
		var modelID = this.getModel().new();

		this.getModel().add( modelID, "container", output );
		this.getModel().add( modelID, "articles", articles );
		this.getModel().add( modelID, "more_information", content );
		this.getModel().add( modelID, "recommendations", recommendations );
		this.getModel().add( modelID, "queryword", this.currentQueryWord );

		let outputMode = this.outputContainer.attr( "data-ez-search-output-mode" );
		if ( outputMode !== "" ) {
			this.getModel().add( modelID, "outputMode", outputMode );
		}

		return this.process( modelID, "head_container" );
	}

	/**************************************** PROCESS SELECTION ****************************************/

	private selection_previous_item  ():void{
		this.selectionChangeItem( -1 );
	}

	private selection_next_item  ():void{
		this.selectionChangeItem( 1 );
	}

	private selectionChangeItem  ( index:number ):void{
		var all_items = this.outputContainer.find( ".search_item" );
		
		var current_selection = this.currentSelection;
		var next_selection = this.currentSelection + index;
		
		if ( next_selection >= 0 && next_selection < all_items.length ) {
			
			var current_item = all_items[ current_selection ] || null;
			var next_item = all_items[ next_selection ];

			var type = next_item.getAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-type" );
			var current_url = next_item.getAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-link" );
			var current_name = next_item.getAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-name" );

			this.currentURL = current_url;
			// this.currentTrackingType = type;

			if ( type == "keyword" ) {
				this.inputField.val( current_name );
			}

			if ( current_item != null ) {
				current_item.setAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-active", "false" );
			}
			next_item.setAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-active", "true" );

			this.currentSelection = next_selection;

		} else if ( next_selection == -1 ) {
			
			all_items[ 0 ].setAttribute( Globals.ATTRIBUTE_PREFIX + "search-item-active", "false" );
			
			this.currentSelection = -1;
			this.inputField.val( this.currentKeyword );
			this.currentURL = ControllerAutocomplete.TARGET_URL_QUERY + encodeURIComponent( this.currentKeyword );
		}
	}

	// private onContentChange  ():void{
	// 	this.assign_content_change_events();
	// }
	private assignThumbEvents(): void {
		let articleLimiter: number = this.getModule().getConfig("article_limiter");
		let searchID: string = this.getSearchID();
		let autocompleteArticles =  Array.from( document.querySelectorAll( "#autocomplete-partTwo" ) );
		let lastSelectedThumb: Element = null;
		

		let categoriesThumbs = Array.from( document.querySelectorAll( "[data-ez-search-item-type='category']" ) );
		let brandThumbs = Array.from( document.querySelectorAll("[data-ez-search-item-type='brand']") );
		let keywordThumbs = Array.from( document.querySelectorAll("[data-ez-search-item-type='keyword']") );

		let output: string = "";
		let clickedThumbName: string = "";
		let clickedThumbEncoded: string = "";
		let clickedThumbLink: string = "";


		if ( categoriesThumbs.length > 0 ) {
			categoriesThumbs.forEach(item => {
				item.addEventListener("click", event => {
					clickedThumbName = item.getAttribute( "data-ez-search-item-name" );
					clickedThumbEncoded = encodeURIComponent( clickedThumbName );
					clickedThumbLink = item.getAttribute( "data-ez-search-item-link" );

					let searchResult = this.getThumbsSearchResults( searchID, "category", clickedThumbEncoded, articleLimiter );
					let processedRow = this.processThumbResults( searchResult, "category", clickedThumbName );
					this.buildShowMoreButton( clickedThumbLink, clickedThumbName );

					output = processedRow;

					this.renderAutocompleteArticles( autocompleteArticles, output );

					this.setSelectedThumb( item, lastSelectedThumb );
					lastSelectedThumb = item;
				});
			})
		}

		if ( brandThumbs.length > 0 ) {
			brandThumbs.forEach(item => {
				item.addEventListener("click", event => {
					clickedThumbName = item.getAttribute( "data-ez-search-item-name" );
					clickedThumbEncoded = encodeURIComponent( clickedThumbName );
					clickedThumbLink = item.getAttribute( "data-ez-search-item-link" );

					let searchResult = this.getThumbsSearchResults( searchID, "brand", clickedThumbEncoded, articleLimiter );
					let processedRow = this.processThumbResults( searchResult, "brand", clickedThumbName );
					this.buildShowMoreButton( clickedThumbLink, clickedThumbName );

					output = processedRow;

					this.renderAutocompleteArticles( autocompleteArticles, output );

					this.setSelectedThumb( item, lastSelectedThumb );
					lastSelectedThumb = item;
				});
			});
		}

		if ( keywordThumbs.length > 0 ) {
			keywordThumbs.forEach(item => {
				item.addEventListener("click", event => {
					clickedThumbName = item.getAttribute( "data-ez-search-item-name" );
					clickedThumbEncoded = encodeURIComponent( clickedThumbName );
					clickedThumbLink = item.getAttribute( "data-ez-search-item-link" );

					let searchResult = this.getThumbsSearchResults( searchID, "keyword", clickedThumbEncoded, articleLimiter );
					let processedRow = this.processThumbResults( searchResult, "keyword", clickedThumbName );
					this.buildShowMoreButton( clickedThumbLink, clickedThumbName );

					output = processedRow;

					this.renderAutocompleteArticles( autocompleteArticles, output );

					this.setSelectedThumb( item, lastSelectedThumb );
					lastSelectedThumb = item;
				});
			});
		}
	}

	private setSelectedThumb( selectedThumb: Element, lastSelectedThumb: Element ): void {
		if ( lastSelectedThumb !== null ) {
			if ( !(selectedThumb == lastSelectedThumb) ) {
				if ( lastSelectedThumb.classList.contains("selected") ) {
					lastSelectedThumb.classList.remove("selected");
				}

				if ( !selectedThumb.classList.contains("selected") ) {
					selectedThumb.classList.add("selected");
				}
			}
		} else {
			if ( !selectedThumb.classList.contains("selected") ) {
				selectedThumb.classList.add("selected");
			}
		}
	}

	private getThumbsSearchResults( searchID: string, category: string, encodedThumb: string, articleLimiter: number ) {
		let result = this.getModel().getSearchResultByCategory( searchID, category, encodedThumb, this.currentQueryWord  );
		result = Json.convertObjectToArray( Json.getSubobject( result, "searchresult.results.domain.resultlist.item" ) ).splice(0, articleLimiter);

		return result;
	}

	private buildShowMoreButton( clickedThumbLink: string, clickedThumbName: string, query_word: string = "" ): void {
		let buttonContainer = Array.from( document.querySelectorAll( "#show-more-button-container" ) );
		let showMoreText = "";

		if ( clickedThumbLink !== "" && clickedThumbName !== "" ) {
			showMoreText = this.getModule().getLabel("search_button_text")
		} else {
			if ( query_word !== "" ) {
				clickedThumbName = query_word;
			}
			showMoreText = this.getModule().getLabel("search_button_text")
		}

		let showMoreButton = `
			<a class="btn btn-primary mt-2 mb-2" href="${clickedThumbLink}">
				${showMoreText} ${clickedThumbName}
			</a>
		`;

		buttonContainer.forEach(item => {
			item.innerHTML = showMoreButton;
		});
	}

	private getSearchID(): string {
		return modules.getGlobalConfig("caspardo_language_mapping." + modules.getLanguageCode());
	}

	private renderAutocompleteArticles( autocompleteOutputs, output: string ): void {
		autocompleteOutputs.forEach(container => {
			container.innerHTML = output;
			container.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
		})
	}

	private calculateShownThumbs(): void {
		let categoriesContainer = Array.from( document.querySelectorAll( ".tags-categories" ) );
		let brandsContainer = Array.from( document.querySelectorAll( ".tags-brands" ) );
		let keywordsContainer = Array.from( document.querySelectorAll( ".tags-keywords" ) );
		let showMoreButton;

		if ( categoriesContainer.length > 0 ) {
			categoriesContainer.forEach(container => {
				let childs = Array.from( container.children );
				if ( childs.length - 1 > ControllerAutocomplete.MAX_SHOW_THUMBS ) {
					childs.shift()
					this.hideOtherThumbs( childs );
				} else {
					showMoreButton = Array.from( document.querySelectorAll( ".open-search-container[data-ez-open-target='.tags-categories']" ) );
					if ( showMoreButton.length > 0 ) {
						showMoreButton.forEach(button => {
							button.classList.add("d-none");
						});
					}
				}
			});
		}

		if ( brandsContainer.length > 0 ) {
			brandsContainer.forEach(container => {
				let childs = Array.from( container.children );
				if ( childs.length - 1 > ControllerAutocomplete.MAX_SHOW_THUMBS ) {
					childs.shift()
					this.hideOtherThumbs( childs );
				} else {
					showMoreButton = Array.from( document.querySelectorAll( ".open-search-container[data-ez-open-target='.tags-categories']" ) );
					if ( showMoreButton.length > 0 ) {
						showMoreButton.forEach(button => {
							button.classList.add("d-none");
						});
					}
				}
			});
		}

		if ( keywordsContainer.length > 0 ) {
			keywordsContainer.forEach(container => {
				let childs = Array.from( container.children );
				if ( childs.length - 1 > ControllerAutocomplete.MAX_SHOW_THUMBS ) {
					childs.shift()
					this.hideOtherThumbs( childs );
				} else {
					showMoreButton = Array.from( document.querySelectorAll( ".open-search-container[data-ez-open-target='.tags-categories']" ) );
					if ( showMoreButton.length > 0 ) {
						showMoreButton.forEach(button => {
							button.classList.add("d-none");
						});
					}
				}
			});
		}
	}

	private hideOtherThumbs( thumbs ): void {
		if ( thumbs.length > 0 ) {
			for ( var i = 0; i < thumbs.length; i++ ) {
				if ( i > 3 ) {
					if ( !thumbs[i].classList.contains( "d-none" ) ) {
						thumbs[i].classList.add("d-none");
					}
				}
			}
		}
	}

	private showOtherThumbs( thumbs ): void {
		if ( thumbs.length > 0 ) {
			for ( var i = 0; i < thumbs.length; i++ ) {
				if ( thumbs[i].classList.contains( "d-none" ) ) {
					thumbs[i].classList.remove("d-none");
				}
			}
		}
	}

	private assignOpenSearchContainersEvents(): void {
		this.calculateShownThumbs();
		let openSearchContainerButtons = Array.from( document.querySelectorAll( ".open-search-container" ) );

		if ( openSearchContainerButtons.length > 0 ) {
			openSearchContainerButtons.forEach(item => {
				jQuery(item).off().on("click", function() {
					let target = item.getAttribute( "data-ez-open-target" );
					let status = item.getAttribute( "data-ez-target-is" );
					let targetElement = Array.from( document.querySelectorAll( target ) );
					if ( targetElement.length > 0 ) {
						targetElement.forEach( container => {
							let childs = Array.from( container.children );
							if ( childs.length - 1 > ControllerAutocomplete.MAX_SHOW_THUMBS ) {
								if ( status === "closed" ) {
									childs.shift()
									this.showOtherThumbs( childs );
									item.setAttribute("data-ez-target-is", "open");
								}
								if ( status === "open" ) {
									childs.shift()
									this.hideOtherThumbs( childs );
									item.setAttribute("data-ez-target-is", "closed");
								}
							}
						});
					}
				}.bind(this));
			});
		}

		let closeOutputButtons = Array.from( document.querySelectorAll( ".close-output-button" ) );
		if ( closeOutputButtons.length > 0 ) {
			let outputContainer = this.outputContainer;
			closeOutputButtons.forEach(button => {
				button.addEventListener("click", function() {
					Elements.hideElement( outputContainer );
					this.setNewMobileView("close");
				}.bind(this));
			});
		}
	}
}