import { HTMLModule } from "../../classes/mvc/HTML/HTMLModule";
import { ControllerHeaderSync } from "./ControllerHeaderSync";
import { ModelHeaderSync } from "./ModelHeaderSync";

export class ModuleHeaderSync extends HTMLModule<ModelHeaderSync> {
    public constructor( configuration:Object ) {
        super( "HeaderSync", configuration )

        this.initOne( ControllerHeaderSync.prototype )
    }

    public run(): void {
        this.runAllControllers();
    }

    public onControllersInitialized(): void {

    }
}