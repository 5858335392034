import { Module } from "../../classes/mvc/Module";
import { ControllerDeliveryAddresses } from "./ControllerDeliveryAddresses";
import { ModelDeliveryAddresses } from "./ModelDeliveryAddresses";

export class ModuleDeliveryAddresses extends Module<ModelDeliveryAddresses> {

    public constructor( configutation:Object ) {
        super( "DeliveryAddresses", configutation );

        var outputButtonContainer = jQuery("[data-ez-module-deliveryaddresses]");
        var outputDesktopContainer = jQuery("[data-ez-module-deliveryaddresses-desktop]");

        if( outputButtonContainer.length > 0 ) {
            var controller = new ControllerDeliveryAddresses( this.getName(), this.getAccessID(), outputButtonContainer, outputDesktopContainer );

            this.addController(controller);
        }
    }

    public run() {
        this.runAllControllers();
    }

    public onControllersInitialized(): void {  }
}