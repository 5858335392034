import Handlebars = require( "handlebars" );
import { Controller } from "../../classes/mvc/Controller";
import { Notifications } from "../../classes/Notifications";
import { modules } from "../../main";
import { ICountryItem } from "./Interfaces/ICountriesData";
import { ICountryChangeResponse } from "./Interfaces/ICountryChangeResponse";
import { ModelDeliverCountries } from "./ModelDeliverCountries";

declare let ezentrum_session_vars: any;
export class ControllerDeliverCountries extends Controller<ModelDeliverCountries> {

    private deliverCountries: Array<ICountryItem> = new Array();
    private outputContainer: JQuery<HTMLElement>;
    private capitalizeStrings: boolean = false;
    private htmlTemplate: string;
    private sKontaktID: string;
    private sKontaktKEY: string;
    private static Notify: Notifications


    public constructor( accessName: string, accessID: number, outputContainer:JQuery<HTMLElement>  ) {
        super( new ModelDeliverCountries(), accessName, accessID );

        this.outputContainer = outputContainer;
    }

    public initGlobals(): void {
        this.htmlTemplate = this.getModule().getConfig("output_template"); // laden des Templates aus der Conf
        this.capitalizeStrings = this.getModule().getConfig("capitalizeStrings") === true; // entscheidet, ob die Länder Strings formatiert werden oder nicht
        this.sKontaktID = modules.getKontaktID(); // getten der sKontaktID
        this.sKontaktKEY = modules.getKontaktKey(); // getten des sKontaktKEY

        /**
         * Überpfrüfung der ezentrum_sesssion_vars, ob 'deliver_countries' vorhanden ist
         * TRUE => Wird nur das 'deliver_countries' Array genommen
         * FALSE => Wird eine Fehlermeldung in der Konsole ausgegeben, dass die 'deliver_countries' nicht vorhanden sind.
         */
        if ( 'deliver_countries' in ezentrum_session_vars ) {
            this.deliverCountries = ezentrum_session_vars.deliver_countries;
        } else {
            modules.error("'deliver_countries' existiert nicht in den 'ezentrum_session_vars'!");
        }

        ControllerDeliverCountries.Notify = new Notifications();
    }

    public run(): void {
        /**
         * Überprüfung, ob 'deliver_countries' einträge hat.
         * TRUE => Wird das Dropdown gerendert.
         * FALSE => Wird wieder eine Fehlermeldung in der Konsole ausgegeben.
         */
        if ( this.deliverCountries.length > 0 ) {
            if ( this.capitalizeStrings ) {
                this.capitalizeOnlyFirstLetter();
            }
            this.renderHTML( this.deliverCountries );
        } else {
            modules.error("'deliver_countries' ist leer oder existiert nicht!");
        }
    }

    private capitalizeOnlyFirstLetter(): void {
        for (var i: number = 0; i < this.deliverCountries.length; i++) {
            var newString: string = this.deliverCountries[i].country_name.toLocaleLowerCase();
            newString = newString.charAt(0).toLocaleUpperCase() + newString.slice(1);

            this.deliverCountries[i].country_name = newString;
        }
    }

    private renderHTML( countries: Array<ICountryItem> ): void {
        // Überprüft, ob das HTML-Template aus der Conf nicht leer ist und rendert das Template falls nicht.
        if ( this.htmlTemplate !== "" ) {
            let handlebarTemplate: HandlebarsTemplateDelegate<any> = Handlebars.compile( this.htmlTemplate );
            let result: string = handlebarTemplate(countries);
            this.outputContainer.html(result);

            this.assignEvents();
        }
    }

    private assignEvents(): void {
        // Zwischenspeicherung der sKontakdID und des sKontanktKEY's.
        var sKontaktID: string = this.sKontaktID;
        var sKontaktKEY: string = this.sKontaktKEY;

        jQuery('.selectpicker').on('changed.bs.select', function(e) {
            // Getten der Land-ID von der ausgewählten Option aus dem Dropdown
            var tempTarget: any = e.target;
            let selectedVal: number = Number(tempTarget.selectedIndex);
            var selected: HTMLElement = e.target[selectedVal];
            var selectedID: string = selected.dataset.id;

            // Aufbau der URL für den AJAX-Call
            let url: string = "/4DCGI/ezshop?action=JSON_setLieferland&landid=" + selectedID + "&sKontaktID=" + sKontaktID + "&sKontaktKEY=" + sKontaktKEY;

            // Call an den Server, um das Lieferland zu wechseln.
            jQuery.ajax({
                url: url,
                type: "GET",
                success: function( data: ICountryChangeResponse ) {
                    if ( data.status == true ) {
                        // es wurde erfolgreich das Lieferland gewechselt
                        ControllerDeliverCountries.Notify.sucess( true, 'Lieferland', 'Das Lieferland wurde erfolgreich geändert!', 'top-right', 3 );
                    } else if ( data.status == false ) {
                        // es ist ein fehler aufgetreten beim wechseln des Lieferlandes
                        ControllerDeliverCountries.Notify.error( true, 'Lieferland', 'Es gab ein Fehler beim wechseln des Lieferlandes!', 'top-right', 3 );
                    }
                },
                error: function(error) {
                    modules.error("Fehler beim ändern des Lieferlandes!", true);
                }
            });
        });
    }
}