import { Module } from "../../classes/mvc/Module";
import { ModuleConfig } from "../../classes/mvc/moduleConfig/ModuleConfig";
import { ControllerDeliverCountries } from "./ControllerDeliverCountries";
import { ModelDeliverCountries } from "./ModelDeliverCountries";

@ModuleConfig({
    moduleName: "DeliverCountries",
    style: "deliverCountries.scss"
})

export class ModuleDeliverCountries extends Module<ModelDeliverCountries> {

    public constructor ( configuration:Object ){
        super( "DeliverCountries", configuration );

        var outputContainer = jQuery("[data-ez-module-delivercountries]");
        
        if ( outputContainer.length > 0 ) {
            var controller = new ControllerDeliverCountries(this.getName(), this.getAccessID(), outputContainer);

            this.addController(controller);
        }
    }

    public run() {
        this.runAllControllers();
    }

    public onControllersInitialized(): void {

    }
}