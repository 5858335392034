import { HTMLController } from "../../classes/mvc/HTML/HTMLController";
import { ModelFooterSync } from "./ModelFooterSync";

declare let ezentrum_session_vars: any;
export class ControllerFooterSync extends HTMLController<ModelFooterSync> {

    public constructor(accessName: string, accessID: number, footerContainer) {
        super( new ModelFooterSync(), accessName, accessID, footerContainer );
    }

    public initGlobals(): void { }

    public run(): void { }
}