import { Module } from "../../classes/mvc/Module";
import { ControllerGMaps } from "./ControllerGMaps";
import { ModelGMaps } from "./ModelGMaps";

export class ModuleGMaps extends Module<ModelGMaps> {

    public constructor( configuration:Object ) {
        super("GMaps", configuration);

        let outputContainer = document.querySelector<HTMLDivElement>("[data-ez-module-gmaps]");
        if (outputContainer) {
            let accessID = this.getAccessID();
            let controller = new ControllerGMaps( this.getName(), accessID, outputContainer );

            this.addController(controller);
        }
    }

    public run() {
        this.runAllControllers();
    }

    public onControllersInitialized(): void {

    }
}