import { ModelMenu } from "./ModelMenu";
import { Json } from "./../../libs/Json";
import { modules } from "../../main";
import { HTMLController } from "./../../classes/mvc/HTML/HTMLController";
import { Arrays } from "./../../libs/Arrays";
import jQuery = require( "jquery" );
import { Globals } from "../../classes/Globals";
import { Logging } from "../../classes/logging/Logging";
import { Modules } from "../../Modules";

declare let ezentrum_variables:any;

declare let ezentrum_session_vars: any;

export class ControllerMenu extends HTMLController<ModelMenu> {

	private static MENU_FILE:Object;
	private static FULL_SEO_LINK:string;
	// data-configurations
	private position:string; // data-ez-param-pos=[main_navi|header_navi|footer_navi]
	private templatename:string;   // data-ez-param-template=[side_nav|header_navi]
	private template:Object;  // YAML-Datei: modules/Menu/components/foundation/template/$this.templatename --> assign the template for the menu from the YAML-File to process. (Template consists of the following nodes: container, rows)
	private fallback:string; // TODO: Check if we need this option any more.
	private entryPoint:string; // TODO: Check if we need this option any more.
	private baseLinkPath:string;
	private useAllItems:boolean; // data-ez-param-use-all=["true"|"false"]
	private isInSideNav:boolean = false;
	private isInMainNav:boolean = false;

	// Global YAML Configurations
	private menuFileBasePath:string; // Menu-File-Basepath is the base-path for the menu.json file to load. YAML-Location: /modules/menu/config/root_folder

	public constructor ( accessName:string, accessID:number, element:JQuery<HTMLElement> ){
		super( new ModelMenu(), accessName, accessID, element );
	}

	// method: initGlobals
	// Global init method.
	// 1. Set Full-SEO-Link
	// 2. Sets the url-location for the menu-json-file

	public initGlobals ():void{
		if ( Modules.isInShopView ) {
			ControllerMenu.FULL_SEO_LINK = ezentrum_variables.full_seo_link;
		} else {
			if ( Modules.modulesVariables.hasOwnProperty("required") ) {
				console.log( "Modules modulesVariables:" );
				console.log( Modules.modulesVariables );
				ControllerMenu.FULL_SEO_LINK = Modules.modulesVariables.sb.full_seo_link;
			}
		}
		
		var rootFolder = this.getModule().getConfig("root_folder");
		rootFolder = ( rootFolder == null ? "": rootFolder );

		// Build the menu file base path
		this.menuFileBasePath = window.location.origin;
		if ( rootFolder != "" ){
			this.menuFileBasePath += "/" + rootFolder; // test.domain.de/rootFolder/menu-de.json
		} else {
			this.menuFileBasePath += "/menu"; // URL: test.domain.de/menu/menu-de.json
		}
		this.menuFileBasePath += "/menu-";
		// Get the menu file
		ControllerMenu.MENU_FILE = this.getModel().getMenuFile( this.menuFileBasePath );
    }

	/*
		Example: HTML-Tag for the navigation
		Parameters: 
		
		- data-ez-param-template=[side_nav|header_navi] : Configuration HTML-Template in the yaml configuration. Please check your yaml-configuration.
			- option "side_nav" : YAML-Defintion for the side-navigation.
			- option "header_navi" : YAML-Defintion the the header-navigation.

		- data-ez-module-menu="" : [""] Empty value. Mandatory for initialize the navigation. Without this data-parameter the navigation will not be rendered.
		
		- data-ez-param-pos=[main_navi|header_navi|footer_navi]
			- option "main_navi" : Rendering only main navigation elements from the navigtion definition (External Links and all other not main elements will not be inculded)
			- option "header_navi" : Rendering of the header navigation. It includes all navigation-entries where the positions are set to "header_navi".
			- option "footer_navi" : Rendering ot the footer navigation. It includes all navigation-entires where the positions are seth the "footer_navi".

			- Example-Navigation-Node (head-node in the menu.json -> positions array defines the visibilty of the node. To render a header-navi them data-paramenter for the html-element needs to be set to data-ez-param-pos="header_navi" )
				"head": {
					"level": 1,
					"position": 1,
					"article_count": 0,
					"parent_article_count": 0,
					"positions": [
						"header_navi",
						"footer_navi"
					]
				}
				
		- data-ez-param-use-all=["true"|"false"]
			option : "true" -> renders all nodes, even if sub-elements have no-hit entries
			option : "false" -> renders only nodes this hits>0. Be carefull when you will active this mode, because if all sub-elements have 0 hits, the main node will not be display.

		Example: Header-Navigation-HTML-Element
		------------------------------------------
		<div id="navi-2" data-ez-module-menu="" data-ez-param-pos="header_navi" data-ez-param-template="header_navi" data-ez-param-use-all="true">
			<ul data-ez-output></ul>
		</div>

		Example: Main-Side-Navigation-HTML-Element
		------------------------------------------
		<div id="navi-2" data-ez-module-menu="" data-ez-param-pos="main_navi" data-ez-param-template="side_nav" data-ez-param-use-all="true">
			<ul data-ez-output></ul>
		</div>

	*/

	public run ():void{
		if ( ControllerMenu.MENU_FILE != null ) // Is a menu-json-file defined?
		{
			// Get the defined template for the menu from html-data-attributes
			this.templatename = this.getParam( "template" );

			switch( this.templatename ) {
				case "side_nav":
					this.isInSideNav = true;
					break;
				case "main_nav":
					this.isInMainNav = true;
					break;
			}

			if ( this.templatename !== null ) {

				// load all configurations for the menu from data-attributes + yaml setup
				this.template = this.getModule().getComponent( "templates." + this.templatename );
				this.position = this.getParam( "pos" );
				this.fallback = this.getParam( "fallback" ); //TODO: Check if fallback will be needed anymore
				this.entryPoint = this.getParam( "entry" ); // TODO: Check if we need this option any more.
				this.baseLinkPath = "";
				this.useAllItems = this.getParam( "use-all" ) == "true"; 

				if ( this.position == null ) this.position = "";
				if ( this.fallback == null ) this.fallback = ""; 
				if ( this.entryPoint == null ) this.entryPoint = ""; 
				if ( this.useAllItems == null ) this.useAllItems = false;

				if ( typeof ControllerMenu.FULL_SEO_LINK !== "undefined" ) {
					if ( this.template != null )
					{
						//Set the entry point
						var items:Array<Object> = Json.getSubobject( ControllerMenu.MENU_FILE, "elements" );
						if ( this.entryPoint != "" ){
							var entry:Array<Object> = this.findEntryPoint( items, this.entryPoint );
							if( entry != null ){
								this.baseLinkPath = this.entryPoint;
								items = entry;
							}
						}
						// Start processing
						this.outputElements( this.processAll( items ) );	
					} else 
					{
						this.getModule().error( Globals.MODULE_LOADING_ERROR + " das folgende Template nicht gefunden wurde: " + this.templatename );
					}
				} else {
					this.getModule().error( Globals.MODULE_LOADING_ERROR + " folgende Variable fehlt: ezentrum_variables.full_seo_link" );
				}

			} else {
				this.getModule().error( Globals.MODULE_LOADING_ERROR + " kein Template angegeben wurde" );
			}
		} else {
			this.getModule().error( Globals.MODULE_LOADING_ERROR + " keine der folgenden Menüdateien geladen werden konnte: " + this.menuFileBasePath + "[sprachID z.B 1].json oder" + this.menuFileBasePath + "[sprachCode z.B. de].json" );
		}
	}

	private findEntryPoint( items:Array<Object>, path:string ):Array<Object>{
		var result:Array<Object> = null;
		var pathItems:Array<string> = path.split( "/" );
		for (let i = 0; i < pathItems.length; i++) {
			for (let j = 0; j < items.length; j++) {
				if( Json.getSubobject( items[j], "body.link" ) == pathItems[i] ){

					if( i == pathItems.length - 1 ){
						result = Json.getSubobject( items[j], "sub_elements" );
					} else {
						result = this.findEntryPoint( Json.getSubobject( items[j], "sub_elements" ), Arrays.removeByIndex( pathItems, i ).join("/") );
					}
					break;
				}
			}
			break;
		}
		return result;	
	}

	// Method: outputElements
	// Generates from one html-element for the resulting html-string
	private outputElements ( content:string ):void{
		var element:JQuery<HTMLElement> = content != "" ? jQuery( content ) : jQuery( this.fallback );
		if( element.length ){
			if ( !this.setOutputElement( element ) ){
				this.getElement().append( element );
			}
		}
	}
	
	// Method: processAll
	// Central method processes the entries of each level
	private processAll ( elements:Array<Object> ):string{
		var result = null
		if ( elements != null ) {
			this.getModule().addView( "main_container", Json.getSubobject( this.template, "main_container" ) ) 
			result = this.processOne( "main_container", "elements", this.processLevel( elements ) );
			this.getModule().clearViews();
		}
		return result;
	}

	// Method: processLevel
	// Central method processes the entries of each level
	private processLevel ( elements:Array<Object>, lastlink:string = "", is_subelement:boolean = false ):string{
		var result:string = "";
		if ( elements != null ) {

			for (var i = 0; i < elements.length; i++) {
				var modelID = this.getModel().new();

				var last_link = lastlink;
				if ( !is_subelement ) { last_link = ""; }

				var element = elements[i];
				var allowed_positions:Array<string> = Json.getSubobject( element, "head.positions" );

				if ((allowed_positions.indexOf( this.position ) >= 0) || (this.useAllItems))
				{

					var currentFullLink:string = "";
					var current_template:string = "";

					var level:number = Json.getSubobject( element, "head.level" );
					var sbid:number = Json.getSubobject( element, "body.sbid" );
					var link:string = Json.getSubobject( element, "body.link" );
					var all_subelements:Array<Object> = Json.getSubobject( element, "sub_elements" );
					var allowed_subelements:Array<Object> = new Array();

					/**
					 * 
					 * Check use all flag
					 */
					if( this.useAllItems ) // Process all entries from level 1, ignore if sub-elements have 0 positions. 
					{
						allowed_subelements = all_subelements;
					} else  
					// Process all entries from level 1 and display only entries that have more than 0 entries. 
					// Note: If all entries for the sub-elements have 0 hits, then the main node for example on level-1 will not be displayed.
					// Please consider this for the navigation and if a main node will not be displayed, then please check, whether all sub-items have 0 positions.
					// If you want to display the main entry even if all sub-elements have 0 positions, then activate this.useALLItems.
					{

						// Get all allowed sub items
						for (var j = 0; j < all_subelements.length; j++) {
							var tmp_allowed_positions:Array<string> = Json.getSubobject( all_subelements[j], "head.positions" );
							if ( tmp_allowed_positions.indexOf( this.position ) >= 0 ){
								allowed_subelements.push( all_subelements[j] );
							}
						}
					}
					if ( allowed_subelements.length > 0 ) 	// Process item with sub elements
					{
						last_link += ( last_link == "" ? "" : "/" ) + link;
						var currentContainer:string = Json.getSubobject( this.template, "containers." + level );
						if ( currentContainer != null ){
							current_template = "current_container_" + level;

							this.getModule().addView( current_template, currentContainer );
							this.getModel().add( modelID, "elements", this.processLevel( allowed_subelements, last_link, true ) );
						}
						currentFullLink = last_link;
					} else {
						/**
						 * 
						 * Process single item
						 */
						var currentRow:string = Json.getSubobject( this.template, "rows." + level );
						if ( currentRow != null ){
							current_template = "current_row_" + level;
							this.getModule().addView( current_template, currentRow );
						}

						currentFullLink = last_link + ( last_link == "" ? "" : "/" ) + link;
					}

					if ( this.baseLinkPath != "" ){
						currentFullLink = this.baseLinkPath + "/" + currentFullLink;
					}

					if ( current_template != "" ) // Is a temmplate defined in the yaml file
					{
						// Check if the current item is active
						var sameValueCount:number = 0;
						var fullSEOLinkItems:Array<string> = ControllerMenu.FULL_SEO_LINK.split( "/" );
						var currentFullLinkItems:Array<string> = currentFullLink.split( "/" );

						for (let j = 0; j < currentFullLinkItems.length; j++) {
							if ( fullSEOLinkItems[j] !== undefined ){
								if ( fullSEOLinkItems[j] == currentFullLinkItems[j] ){
									sameValueCount++;
								}
							}
						}
						var active:boolean = sameValueCount == currentFullLinkItems.length;
						/**
						 * 
						 * All all global items to the model
						 */
						this.getModel().add( modelID, "head", Json.getSubobject( element, "head" ) );
						this.getModel().add( modelID, "body", Json.getSubobject( element, "body" ) );
						this.getModel().add( modelID, "active", active );
						var display:boolean=this.checkDisplayStatus(sbid, level); // Central Method for display checking. Implementation "display" in YAML configuration.
						this.getModel().add( modelID, "display", display );
						this.getModel().add( modelID, "isInSideNav", this.isInSideNav );
						this.getModel().add( modelID, "isInMainNav", this.isInMainNav );
						this.getModel().add( modelID, "children", allowed_subelements.length );
						this.getModel().add( modelID, "first_item", i == 0);
						this.getModel().add( modelID, "last_item", i == elements.length - 1);
						this.getModel().add( modelID, "count", i);
						this.getModel().add( modelID, "curr_link", ControllerMenu.FULL_SEO_LINK );
						this.getModel().add( modelID, "language", modules.getLanguageCode());

						if ( sbid == 0 ){
							this.getModel().add( modelID, "curr_item_link", link );
						} else {
							this.getModel().add( modelID, "curr_item_link", currentFullLink );
						}

						/**
						 * 
						 * Process Handlebars
						 */
						result += this.process( modelID, current_template, true );
					}
				}
			}

		}

		return result;
	}

	// Method: checkDisplayStatus	
	// Processing one entry in the navigation.
	// All elements of the navigation will call this method to check if one navigation entry will be displayed.
	private checkDisplayStatus (sbid:number, level:number):boolean{
		var result:boolean = true;

		if ( Modules.isInShopView ) {
			if ( ezentrum_session_vars.Navi_Master_SBID===undefined ) return true;
		} else {
			if ( Modules.modulesVariables.Navi_Master_SBID===undefined ) return true;
		}
		
		// Find Navi-Master-SB Entries
		var resMasterSBIDIndex;
		var mandand_spea_mode:boolean;

		if ( Modules.isInShopView ) {
			resMasterSBIDIndex = ezentrum_session_vars.Navi_Master_SBID.findIndex(entry => entry === sbid);
			mandand_spea_mode = ezentrum_session_vars.mandant_flag_spea;
		} else {
			resMasterSBIDIndex = Modules.modulesVariables.Navi_Master_SBID.findIndex(entry => entry === sbid);
			mandand_spea_mode = Modules.modulesVariables.mandant_flag_spea;
		}
		
		this.log("Navi_Master_SBID-Index:"+resMasterSBIDIndex,"");

		if ( Modules.isInShopView ) {
			this.log( "Navi_Master-SBID-CountEntries:" + ezentrum_session_vars.Navi_Master_SBCount[resMasterSBIDIndex], "" );
		} else {
			this.log( "Navi_Master-SBID-CountEntries:" + Modules.modulesVariables.Navi_Master_SBCount[resMasterSBIDIndex], "");
		}
		
		if (mandand_spea_mode)
		{
			this.log("checkDisplayStatus: Navi-Spea-Mode: active, Level="+level,"");
			if ( Modules.isInShopView ) {
				this.log("customer-spea-ids: " + ezentrum_session_vars.Customer_SPEASBIDs, "");
			} else {
				this.log( "customer-spea-ids: " + Modules.modulesVariables.Customer_SPEASBIDs, "" );
			}
			// Find all SPEA-Index-Entries for the SBID
			var resSBIDSpeaMatchIndex=[];

			var resSPEASBCount=[]; // SPEA-SBID-Match-Count Array -> Contains all SPEA-SBIDs-Counts = Hits for the SPEA-SBID-Articles
			var resSPEASBIDs=[]; // SPEA-SBId-Match Array -> Contains all SBIds that matches to the ASSIGENDSBIDs.
			var resSPEAID=[]; // SPEA-ASSIGENDSBIDs-TO-SBID

			var i = -1;
			if ( Modules.isInShopView ) {
				while ((i = ezentrum_session_vars.Navi_SPEA_SBID.indexOf(sbid, i+1)) != -1) {
					resSBIDSpeaMatchIndex.push(i);
				}
			} else {
				while ((i = Modules.modulesVariables.Navi_SPEA_SBID.indexOf(sbid, i+1)) != -1){
					resSBIDSpeaMatchIndex.push(i);
				}
			}
			

			// Loop through the SBID-ZART-Matches and fill up the ZART-Match-Arrays
			for (var i = 0; i < resSBIDSpeaMatchIndex.length; i++) {
				var index=resSBIDSpeaMatchIndex[i];
				if ( Modules.isInShopView ) {
					resSPEASBCount.push(ezentrum_session_vars.Navi_SPEA_COUNT[index]);
					resSPEASBIDs.push(ezentrum_session_vars.Navi_SPEA_SBID[index]);
					resSPEAID.push(ezentrum_session_vars.Navi_SPEA_ID[index]);
				} else {
					resSPEASBCount.push( Modules.modulesVariables.Navi_SPEA_COUNT[index] );
					resSPEASBIDs.push( Modules.modulesVariables.Navi_SPEA_SBID[index] );
					resSPEAID.push( Modules.modulesVariables.Navi_SPEA_ID[index] );
				}
			}

			this.log("SPEA-SBID-Count: "+resSPEASBCount.toString(),"");
			this.log("SPEA-SBID: "+resSPEASBIDs.toString(),"");
			this.log("SPEA-SBID-ASSIGNED-TO-SBID: "+resSPEAID.toString(),"");

			// SPEA SB-Article-Count will replace the original SB-Article-Count for the Navigation
			var isSPEA:boolean=false;
			var speaSBIDCount:number=0;
			for (var i = 0; i < resSPEASBIDs.length; i++) {
				var speaSBID:number=resSPEAID[i]; 
				var findSPEAID:number;

				if ( Modules.isInShopView ) {
					findSPEAID = ezentrum_session_vars.Customer_SPEASBIDs.indexOf(speaSBID);
				} else {
					findSPEAID = Modules.modulesVariables.Customer_SPEASBIDs.indexOf(speaSBID);
				}

				if (findSPEAID>=0)
				{
					isSPEA=true;
					var addSPEAHits:number=resSPEASBCount[i];
					speaSBIDCount+=addSPEAHits;
					this.log("Identified SPEA-SBID-ASSIGNED-TO-SBID: "+speaSBID+"->"+sbid+", Article-Count:"+addSPEAHits,"");
				}
			}

			if (isSPEA===true) // Replace the Navigation-Hits with SPEA-Sum of SPEA-SB-ID-Counts
			{
				var findMasterSBID:number;

				if ( Modules.isInShopView ) {
					findMasterSBID = ezentrum_session_vars.Navi_Master_SBID.indexOf(sbid);
				} else {
					findMasterSBID = Modules.modulesVariables.Navi_Master_SBID.indexOf(sbid);
				}

				if (findMasterSBID>=0)
				{
					var resSPEADisplay:boolean=(speaSBIDCount>=1);
					this.log(resSPEADisplay.toString()+" - SPEA-Navigation-Hits-Replacement: NumberOfArticles="+speaSBIDCount,"");
					return resSPEADisplay;
				}
				else // Hide Entry
					return false;
			}else // Hide Entry
				return false;

		} else
		{
			this.log("checkDisplayStatus: ZART-Mode: active, Level="+level,"");
			var resSBIDZartMatchIndex = []; // ZART-SBID-Match-Index Array
			var resZARTSBCount=[]; // ZART-SBID-Match-Count Array -> Contains all ZART-SBIDs-Counts = Hits for the ZART-SBID-Articles
			var resZARTSBIDs=[]; // ZART-SBId-Match Array -> Contains all SBIds that matches to the ZART-SBID.
			var resZARTID=[]; // ZART-ID -> SBIDs for all zart-ids that match to the SBIDs
			var i = -1;

			// Find all ZART-Index-Entries for the SBID
			if ( Modules.isInShopView ) {
				while ((i = ezentrum_session_vars.Navi_ZART_SBID.indexOf(sbid, i+1)) != -1){
					resSBIDZartMatchIndex.push(i);
				}
			} else {
				while ((i = Modules.modulesVariables.Navi_ZART_SBID.indexOf(sbid, i+1)) != -1){
					resSBIDZartMatchIndex.push(i);
				}
			}

			// Loop through the SBID-ZART-Matches and fill up the ZART-Match-Arrays
			for (var i = 0; i < resSBIDZartMatchIndex.length; i++) {
				var index=resSBIDZartMatchIndex[i];

				if ( Modules.isInShopView ) {
					resZARTSBCount.push(ezentrum_session_vars.Navi_ZART_COUNT[index]);
					resZARTSBIDs.push(ezentrum_session_vars.Navi_ZART_SBID[index]);
					resZARTID.push(ezentrum_session_vars.Navi_ZART_ID[index]);
				} else {
					resZARTSBCount.push( Modules.modulesVariables.Navi_ZART_COUNT[index] );
					resZARTSBIDs.push( Modules.modulesVariables.Navi_ZART_SBID[index] );
					resZARTID.push( Modules.modulesVariables.Navi_ZART_ID[index] );
				}
				
			}

			// ZART-ID (Calculation of article-hit-numbers for ZART)
			var resZartSBID_Add:number=0;
			var resZartSBID_Minus:number=0;

			for (var i = 0; i < resZARTSBIDs.length; i++) {
				var zartSBID=resZARTID[i];
				var find;

				if ( Modules.isInShopView ) {
					find=ezentrum_session_vars.Customer_ZARTSBIDs.indexOf(zartSBID);
				} else {
					find=Modules.modulesVariables.Customer_ZARTSBIDs.indexOf(zartSBID);
				}

				if (find>=0)
					resZartSBID_Add+=resZARTSBCount[i];
				else
					resZartSBID_Minus+=resZARTSBCount[i];
			}

			this.log("Minus:"+resZartSBID_Minus+", SBID:"+sbid,"");
			this.log("Add:"+resZartSBID_Add+", SBID:"+sbid,"");

			if (resMasterSBIDIndex===-1) // Security Return true : If the SBID will nicht match,then display the entry.
				return true;

			var sbNaviHitCount:number;

			if ( Modules.isInShopView ) {
				sbNaviHitCount = ezentrum_session_vars.Navi_Master_SBCount[resMasterSBIDIndex];
			} else {
				sbNaviHitCount = Modules.modulesVariables.Navi_Master_SBCount[resMasterSBIDIndex];
			}

			var sbNaviHitCountRES:number=sbNaviHitCount-resZartSBID_Minus;

			this.log("SBID:"+sbid+", Display="+result+", NumberOfArts="+sbNaviHitCountRES,"");

			result=(sbNaviHitCountRES>0);

			return result;
		}
	}


  /**
     * @param message the log entry
     * @param outputStyle the outputStyle (css) displayed in the console
     */
    public log(message: string, outputStyle: string = ""): void {
        Logging.GENERAL_LOG.add(message, outputStyle, false);
    }

}