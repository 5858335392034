import { HTMLModule } from "../../classes/mvc/HTML/HTMLModule";
import { ControllerFooterSync } from "./ControllerFooterSync";
import { ModelFooterSync } from "./ModelFooterSync";

export class ModuleFooterSync extends HTMLModule<ModelFooterSync> {
    public constructor( configuration:Object ) {
        super( "FooterSync", configuration );

        this.initOne( ControllerFooterSync.prototype );
    }

    public run(): void {
        this.runAllControllers();
    }

    public onControllersInitialized(): void { }
}