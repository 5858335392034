import { ModelHeaderSync } from "./ModelHeaderSync";
import Handlebars = require("handlebars");
import { Json } from "../../libs/Json";
import { modules } from "../../main";
import { ModuleAutocomplete } from "../Autocomplete/ModuleAutocomplete";
import { IHeaderData } from "./Interfaces/IHeaderData";
import { HTMLController } from "../../classes/mvc/HTML/HTMLController";
import { Modules } from "../../Modules";
import { ModuleMenu } from "../Menu/ModuleMenu";

declare let ezentrum_session_vars: any;
export class ControllerHeaderSync extends HTMLController<ModelHeaderSync> {

    private headerContainerElement;
    private headerTemplate: string;
    private templateName: string;
    private headerData: IHeaderData;
    private labels: Object;
    private hasOffCanvasMenu: boolean = false;
    private hasSlideSearch: boolean = false;

    public constructor( accessName: string, accessID: number, headerContainer ) {
        super( new ModelHeaderSync(), accessName, accessID, headerContainer );

        this.headerContainerElement = headerContainer;
    }

    public initGlobals(): void {
        let labels = this.getModule().getAllLabels();
        if ( labels ) {
            this.labels = labels;
        }
        
        this.headerData = {
            "customer": {
                "city": "",
                "company": "",
                "department": "",
                "fax": "",
                "house_number": "",
                "id": "",
                "lastname": "",
                "loginstatus": false,
                "name": "",
                "number": "",
                "payment_method": 0,
                "phone": "",
                "postcode": "",
                "street": "",
                "vat": ""
            },
            "required": {
                "contact_id": "",
                "contact_key": "",
                "language_number": "",
                "mandant_id": "",
                "sKontaktID": "",
                "sKontaktKEY": "",
                "tickcount": ""
            },
            "shoppingbasket": {
                "sum": "",
                "sum_articles": 0
            }
        }

        let hasOffCanvasMenu = this.getModule().getConfig("hasOffCanvasMenu");
        if ( hasOffCanvasMenu ) {
            this.hasOffCanvasMenu = hasOffCanvasMenu;
        }

        let hasSlideSearch = this.getModule().getConfig("hasSlideSearch");
        if ( hasSlideSearch ) {
            this.hasSlideSearch = hasSlideSearch;
        }
    }

    public run(): void {
        this.setHeaderData();

        this.templateName = this.getParam("template");
        if ( this.templateName === "" ) {
            this.getModule().error("Es konnte kein Template gefunden werden! Bitte überprüfe, ob das Attribut gesetzt ist!");
        } else {
            let header_html: string = this.getModule().getHTMLTemplate( this.templateName );
            if (header_html) {
                this.headerTemplate = header_html;
                this.processLabels();
                this.renderHTML();
                this.setHeaderSearchEvents();
            } else {
                this.getModule().error("Es wurde kein Template mit dem Namen '"+ this.templateName +"' gefunden!");
            }
        }
    }

    private processLabels(): void {
        let keys: Array<string> = Object.keys( this.labels );

        if ( keys.length > 0 ) {
            for (var i = 0; i < keys.length; i++) {
                let stringToReplace = ":label=>" + keys[i] + ":";
                let test = new RegExp(stringToReplace, 'g');
                this.headerTemplate = this.headerTemplate.replace(test, this.labels[keys[i]]);
            }
        }
    }

    private setHeaderData(): void {
        if ( Modules.isInShopView ) {
            this.headerData.customer = ezentrum_session_vars.customer;
            this.headerData.required = ezentrum_session_vars.required;
            this.headerData.shoppingbasket = ezentrum_session_vars.shoppingbasket;
        } else {
            this.headerData.customer = Modules.modulesVariables.customer;
            this.headerData.required = Modules.modulesVariables.required;
            this.headerData.shoppingbasket = Modules.modulesVariables.shoppingbasket;
        }
    }

    private renderHTML(): void {
        let handlebarTemplate = Handlebars.compile(this.headerTemplate);
        let result: string = handlebarTemplate( this.headerData );

        jQuery(this.headerContainerElement).html( result );

        this.reInitAutocomplete();
        
        if ( !Modules.isInShopView ) {
            this.reInitMenu();
        }

        if ( this.hasOffCanvasMenu ) {
            jQuery("[data-trigger]").on("click", function(e){
                e.preventDefault();
                e.stopPropagation();
                var offcanvas_id =  jQuery(this).attr('data-trigger');
                jQuery(offcanvas_id).toggleClass("show");
                jQuery('body').toggleClass("offcanvas-active");
                jQuery(".screen-overlay").toggleClass("show");
            });
            
            jQuery(".btn-close, .screen-overlay").on("click", function() {
                jQuery(".screen-overlay").removeClass("show");
                jQuery(".mobile-offcanvas").removeClass("show");
                jQuery("body").removeClass("offcanvas-active");
            });
        }
    }

    public reInitMenu(): void {
        let configuration = Json.getSubobject(modules.modulesConfiguration, "Menu");
        if ( configuration ) {
            let Menu = new ModuleMenu(configuration);
            Menu.run();
        }
    }

    public reInitAutocomplete(): void {
        let configuration = Json.getSubobject(modules.modulesConfiguration, "Autocomplete");
        if ( configuration ) {
            let Autocomplete = new ModuleAutocomplete(configuration);
            Autocomplete.run();
        }
	}

    private setHeaderSearchEvents(): void {
        if ( this.hasSlideSearch ) {
            let openSearchElement = document.getElementById("slideSearch");
            let autocompleteContainer = document.getElementById("search_bar_desktop");
            let closeSearchBarDesktop = document.querySelector("[data-target='#search_bar_desktop']");
    
            openSearchElement.addEventListener("click", event => {
                autocompleteContainer.classList.toggle("show");
                openSearchElement.classList.add("d-none");
                let searchInput = jQuery( autocompleteContainer ).find( "[data-ez-search-input]" );
                if ( searchInput ) {
                    setTimeout(function() { searchInput.focus() }, 400);
                }
            });

            closeSearchBarDesktop.addEventListener("click", event => {
                if ( openSearchElement.classList.contains("d-none") ) {
                    openSearchElement.classList.remove("d-none");
                }
            });
    
            if ( ezentrum_session_vars ) {
                if ( ezentrum_session_vars.response_page === "caspardo.htm" ) {
                    autocompleteContainer.classList.toggle("show");
                    openSearchElement.classList.toggle("d-none");
                }
            } else {
                if ( Modules.modulesVariables.response_page === "caspardo.html" || Modules.modulesVariables.response_page === "caspardo.htm" ) {
                    autocompleteContainer.classList.toggle("show");
                    openSearchElement.classList.toggle("d-none");
                }
            }
        }
    }
}