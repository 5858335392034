declare let GrowlNotification: any;
export class Notifications {

    public alert( showProgress: boolean, title: string, description: string, position: string, closeTimer: number ): void {
        if ( GrowlNotification ) {
            let time: number = this.timeInMS(closeTimer);

            GrowlNotification.notify({
                showProgress: showProgress,
                title: title,
                description: description,
                type: 'alert',
                position: position,
                closeTimeout: time
            });
        }
    }

    public sucess( showProgress: boolean, title: string, description: string, position: string, closeTimer: number ): void {
        if ( GrowlNotification ) {
            let time: number = this.timeInMS(closeTimer);

            GrowlNotification.notify({
                showProgress: showProgress,
                title: title,
                description: description,
                type: 'success',
                position: position,
                closeTimeout: time
            });
        }
    }

    public error( showProgress: boolean, title: string, description: string, position: string, closeTimer: number ): void {
        if ( GrowlNotification ) {
            let time: number = this.timeInMS(closeTimer);

            GrowlNotification.notify({
                showProgress: showProgress,
                title: title,
                description: description,
                type: 'error',
                position: position,
                closeTimeout: time
            });
        }
    }

    public warning( showProgress: boolean, title: string, description: string, position: string, closeTimer: number ): void {
        if ( GrowlNotification ) {
            let time: number = this.timeInMS(closeTimer);

            GrowlNotification.notify({
                showProgress: showProgress,
                title: title,
                description: description,
                type: 'warning',
                position: position,
                closeTimeout: time
            });
        }
    }

    public info( showProgress: boolean, title: string, description: string, position: string, closeTimer: number ): void {
        if ( GrowlNotification ) {
            let time: number = this.timeInMS(closeTimer);

            GrowlNotification.notify({
                showProgress: showProgress,
                title: title,
                description: description,
                position: position,
                closeTimeout: time
            });
        }
    }

    private timeInMS( time: number ): number {
        return time * 1000;
    }
}