import { Controller } from "./../../classes/mvc/Controller";
import { ModelMessageBox } from "./ModelMessageBox";
import { Cookies } from "./../../libs/Cookies";
import { Message } from "./Message";
import jQuery = require( "jquery" );
import { Globals } from "../../classes/Globals";
import { Elements } from "../../libs/Elements";

export class ControllerMessageBox extends Controller<ModelMessageBox>{    
    private static COOKIE_KEY:string;
    private static INSERT_AT:string;

    private static CONF_FILE: Object;

    private messages:Array<Message>;

    public constructor ( accessName:string, accessID:number ){
        super( new ModelMessageBox(), accessName, accessID );
    }

    public initGlobals ():void{
        this.getModule().addView( "structure", this.getModule().getComponent( "structure" ) );

        ControllerMessageBox.COOKIE_KEY = "ezentrum_message_";
        ControllerMessageBox.INSERT_AT = "body";

        var insertAt = this.getModule().getConfig( "insert_at" );
        if( insertAt != null ){
            ControllerMessageBox.INSERT_AT = insertAt;
        }

        var path = this.getModule().getConfig( "conf_file_path" );
        if( path == "" ){
            path = "/resources/modules/message_box.json";
        }

        ControllerMessageBox.CONF_FILE = this.getModel().getConfFile( path );
    }

    public run ():void{
        this.messages = new Array();

        var messageObjects:Array<Object> = ControllerMessageBox.CONF_FILE as Array<Object>;
        if (messageObjects != null){
            
            for (let i = 0; i < messageObjects.length; i++) {
                this.messages.push( new Message( messageObjects[i] ) );
            }
    
            var currentDate:Date = new Date();
            currentDate.setHours(0, 0, 0, 0);
    
            for (let i = 0; i < this.messages.length; i++) {
                var currentMessage:Message = this.messages[i];

                var cookie = Cookies.instance.get( ControllerMessageBox.COOKIE_KEY + currentMessage.getID() );
                var show:boolean = currentDate.getTime() >= currentMessage.getStart().getTime() && currentDate.getTime() <= currentMessage.getStop().getTime();
                
                if ( cookie != "true" && show) {
                    this.display( currentMessage );
                } else if (!show) {
                    this.getModule().log( "Die MessageBox wird nicht angezeigt, da der Anzeigezeitpunkt überschritten wurden Anzeige vom " + currentMessage.getStart() + " bis zum " + currentMessage.getStop() );
                }
            }

        } else {
            this.getModule().error( Globals.MODULE_LOADING_ERROR + " die Konfigurationsdatei konnte nicht geladen werden" );
        }
    }

    private display ( currentMessage:Message ){
        if ( currentMessage.getMessage() != null && currentMessage.getMessage() != "" ) {
                        
            var messageBoxContainer:JQuery<HTMLElement>;
            messageBoxContainer = this.createStructure( currentMessage.getMessage() );

            if (messageBoxContainer!= null && messageBoxContainer.length ){
                this.getModule().addModuleAttribute( messageBoxContainer );
                         
                messageBoxContainer.slideDown();
                
                var messageBoxCloseInput:JQuery<HTMLElement> = Elements.findElement( messageBoxContainer, "close" );
                if ( messageBoxCloseInput.length ) {
                    messageBoxCloseInput.on( "click", function( container:JQuery<HTMLElement>, cookieKey:string ) {
                        container.slideUp();
                        Cookies.instance.set( cookieKey, "true", currentMessage.getStop().toString() );
                    }.bind( this, messageBoxContainer, ControllerMessageBox.COOKIE_KEY + currentMessage.getID() ));
                }

            }
        }
    }

	private createStructure ( message:string ):JQuery<HTMLElement> {
        let result_element:string = "";
        let insert_at_element:JQuery<HTMLElement> = null;
		
        insert_at_element = jQuery( ControllerMessageBox.INSERT_AT );

        if ( !insert_at_element.length ){
            insert_at_element = jQuery( "body" );
        }

        var modelID = this.getModel().new();
        this.getModel().add( modelID, "message", message );

        result_element = this.process( modelID, "structure");
		if ( result_element != "" ) {
            var element = jQuery( result_element );
            
            insert_at_element.prepend( element );

            return element;
		} else {
            return null;
        }
	}

}